import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { PlusCircleIcon } from "lucide-react";
import StudentListCombobox from "./student-list-combobox";
import WorksheetSetVersionListCombobox from "./worksheet-set-version-list-combobox";
import { useInsertWorksheetSetAssignment } from "@/lib/apis/v2/mutations/insertWorksheetSetAssigment";
import { toast } from "sonner";
import { Input } from "./ui/input";

interface AddWorsheetSetAssignmentModalProps {
  role?: string;
  sessionId?: number;
  studentId?: number;
}

export function AddWorksheetSetAssignmentModal(
  props: AddWorsheetSetAssignmentModalProps
) {
  const [userId, setUserId] = useState<number | null>(props?.studentId ?? null);
  const [worksheetSetVersionId, setWorksheetSetVersionId] = useState<
    number | null
  >(null);
  const [sessionId, setSessionId] = useState<number | null>(
    props?.sessionId ?? null
  );

  const [modeInputStudent, setModeInputStudent] = useState<"id" | "search">(
    "id"
  );

  const mutation = useInsertWorksheetSetAssignment();

  const handleSave = () => {
    if (!userId || !worksheetSetVersionId) {
      return;
    }
    try {
      mutation.mutate({
        values: {
          garageClassroomSessionId: Number(sessionId),
          garageUserId: Number(userId),
          worksheetSetVersionId: Number(worksheetSetVersionId),
          isMarkingComplete: false,
        },
      });
      toast.success("Saved!");
      window.location.reload();
    } catch (error) {
      toast.error("Error saving worksheet set assignment");
    }
  };

  return (
    <Dialog>
      <div className="w-full flex justify-end">
        <DialogTrigger asChild>
          <Button variant="secondary" size="sm">
            <PlusCircleIcon className="mr-2 h-4 w-4" /> Add Worksheet Set
          </Button>
        </DialogTrigger>
      </div>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Assign Worksheet Set</DialogTitle>
          <DialogDescription>
            Assign a new Worksheet Set to a student
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Session ID
            </Label>
            <Input
              className="w-full col-span-3"
              type="number"
              placeholder="Garage Session ID"
              value={Number(sessionId)}
              onChange={(e) => setSessionId(Number(e.currentTarget.value))}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              {modeInputStudent === "id" ? "Student ID" : "Search Student"}
            </Label>
            <div className="w-full col-span-3 flex gap-2 items-center">
              <div className="grid gap-1">
                {modeInputStudent === "search" ? (
                  <StudentListCombobox
                    selectedId={Number(userId)}
                    onSelect={(id) => {
                      setUserId(id);
                      setModeInputStudent("id");
                    }}
                  />
                ) : (
                  <Input
                    className="w-full col-span-3"
                    type="number"
                    value={Number(userId)}
                    placeholder="Garage Student ID"
                    onChange={(e) =>
                      setUserId(Number(e.currentTarget.value))
                    }
                  />
                )}
                <button
                  className="text-blue-400 text-xs text-start"
                  onClick={() => {
                    setModeInputStudent(
                      modeInputStudent === "id" ? "search" : "id"
                    );
                  }}
                >
                  {modeInputStudent === "search" ? "Input ID" : "Search Student"}
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Worksheet Set
            </Label>
            <WorksheetSetVersionListCombobox
              selectedId={Number(worksheetSetVersionId)}
              onSelect={(id) => setWorksheetSetVersionId(id)}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              type="submit"
              disabled={!userId || !worksheetSetVersionId || !sessionId}
              onClick={handleSave}
            >
              Save changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
