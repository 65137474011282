import {
  createFileRoute,
  Link,
  Outlet,
  redirect,
} from "@tanstack/react-router";
import { CircleUser, HomeIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { useAuth } from "../lib/useAuth";
import { Button } from "@/components/ui/button";
import NetworkStatusBanner from "@/components/network-status-banner/network-status-banner.tsx";
import NetworkStatusToast from "@/components/network-status-toast/network-status-toast.tsx";

export const Route = createFileRoute("/_auth")({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: AuthLayout,
});

function AuthLayout() {
  const auth = useAuth();
  const navigate = Route.useNavigate();

  const handleLogout = async () => {
    auth.logout().then(() => {
      navigate({ to: "/login" });
    });
  };

  return (
    <div className="flex min-h-screen w-full flex-col">
      <NetworkStatusBanner />
      <NetworkStatusToast />
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 z-50">
        <nav className="flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <Link
            href="#"
            className="flex items-center gap-2 text-lg font-semibold md:text-base"
          >
            <span className="sr-only">Edukita</span>
          </Link>
          <Link to="/home">
            <HomeIcon />
            <span className="sr-only">Home</span>
          </Link>
          <span className="text-foreground transition-colors hover:text-foreground min-w-80">
            Hi {auth.user?.name}!
          </span>
        </nav>

        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <div className="ml-auto flex-1 sm:flex-initial"></div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full">
                <CircleUser className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              {/* <DropdownMenuSeparator />
              <DropdownMenuItem>Settings</DropdownMenuItem>
              <DropdownMenuItem>Support</DropdownMenuItem> */}
              <DropdownMenuItem>{auth.user?.name}</DropdownMenuItem>
              <DropdownMenuItem>
                <Link to="/home">Home</Link>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={handleLogout}
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>

      <hr />
      <Outlet />
    </div>
  );
}
