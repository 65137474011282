import { UpdateWorksheetSetAssignmentDocument } from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useMutation } from "@tanstack/react-query";
import request from "graphql-request";

export function useUpdateWorksheetSetAssigmentAsComplete({
  worksheetSetAssignmentId,
}: {
  worksheetSetAssignmentId: number;
}) {
  return useMutation({
    mutationKey: ["updateWorksheetSetAssigmentAsComplete"],
    mutationFn: () =>
      request(config.graphql.uri, UpdateWorksheetSetAssignmentDocument, {
        set: {
          isMarkingComplete: true,
        },
        where: {
          id: { eq: worksheetSetAssignmentId },
        },
      }),
  });
}
