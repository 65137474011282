import { PAGE_HEIGHT } from "@/components/canvas/constants";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import Canvas from "@/components/canvas/canvas";
import { useWindowSize } from "@/components/canvas/hooks/useWindowSize.tsx";
// import { useQuery } from "@tanstack/react-query";
// import { getWorksheet, getWorksheetSet } from "@/lib/api";
// import { Spinner } from "@/components/ui/spinner";
// import ErrorHandler from "@/components/error-handler";
// import WorksheetSetThumbnails from "@/components/worksheet-set-thumbnails";
import { useGetWorksheetSetVersion } from "@/lib/apis/v2/queries/getWorksheetSetVersion";
import { useGetWorksheetVersion } from "@/lib/apis/v2/queries/getWorksheetVersion";
import { GetWorksheetSetVersionQuery } from "@/graphql/generated/graphql";

export const Route = createFileRoute(
  "/_auth/student/session/$sessionId/set/$setVersionId/worksheet/$worksheetVersionId"
)({
  component: () => <WorksheetPage />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function WorksheetPage() {
  const { sessionId, setVersionId, worksheetVersionId } = Route.useParams();

  const [activePageVersionId, setActivePageVersionId] = useState<number | null>(
    null
  );

  const { data: dt } = useGetWorksheetSetVersion({
    where: {
      id: {
        eq: Number(setVersionId),
      },
    },
  });

  const { data } = useGetWorksheetVersion({
    where: {
      id: {
        eq: Number(worksheetVersionId),
      },
    },
    enabled: !!worksheetVersionId,
  });

  useEffect(() => {
    const initialPageVersionId =
      data?.worksheetVersion?.[0]?.worksheetVersionItems?.[0]?.worksheetPageVersion?.id;
    if (!activePageVersionId && initialPageVersionId) {
      setActivePageVersionId(initialPageVersionId);
    }
  }, [activePageVersionId, data]);

  const getWorksheetInfo = (
    data: GetWorksheetSetVersionQuery | undefined,
    currentWorksheetVersionId: number
  ) => {
    // Ensure worksheets is defined and is an array before proceeding
    const worksheetSetVersionItems =
      data?.worksheetSetVersion?.[0]?.worksheetSetVersionItems ?? [];

    // Find the current worksheet based on its ID
    const currentWorksheetIndex = worksheetSetVersionItems.findIndex(
      (item) => item?.worksheetVersion?.id === currentWorksheetVersionId
    );

    // Check if the currentWorksheetIndex is valid
    const isValidIndex = currentWorksheetIndex !== -1;

    // Check if the current worksheet is the last one, ensure worksheets array is not empty
    const isLastWorksheet =
      isValidIndex &&
      currentWorksheetIndex === worksheetSetVersionItems.length - 1;

    // Get the ID of the next worksheet if it exists and current index is valid
    const nextWorksheetVersionId =
      isValidIndex &&
      currentWorksheetIndex < worksheetSetVersionItems.length - 1
        ? (worksheetSetVersionItems[currentWorksheetIndex + 1]?.worksheetVersion
            ?.id ?? null)
        : null;

    return {
      isLastWorksheet,
      nextWorksheetVersionId,
    };
  };

  const totalPages =
    data?.worksheetVersion?.[0]?.worksheetVersionItems?.length || 0;

  const { isLastWorksheet, nextWorksheetVersionId } = getWorksheetInfo(
    dt,
    Number(worksheetVersionId)
  );
  const size = useWindowSize();

  return (
    <div>
      <Canvas
        width={size.width}
        height={totalPages * PAGE_HEIGHT}
        setVersionId={setVersionId}
        worksheet={{
          currentWorksheetVersionId: worksheetVersionId,
          isLastWorksheet,
          nextWorksheetVersionId: nextWorksheetVersionId,
        }}
        sessionId={sessionId}
        teacherView={false}
      />
    </div>
  );
}
