import { UpsertWorksheetPageAnnotationDocument } from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { Drawing, Textbox } from "@server/shared-types";
import { useMutation } from "@tanstack/react-query";
import request from "graphql-request";
import { AnnotationRole } from "../../../../../../server/db/v2/schema/index";

export function useUpsertWorksheetPageAnnotation() {
  return useMutation({
    mutationKey: ["useUpsertWorksheetPageAnnotation"],
    mutationFn: ({
      worksheetPageVersionId,
      worksheetSetAssignmentId,
      userId,
      role,
      drawing,
      textboxes,
    }: {
      worksheetPageVersionId: number;
      worksheetSetAssignmentId: number;
      userId: number;
      role: AnnotationRole;
      drawing: Drawing[];
      textboxes: Textbox[];
    }) =>
      request(config.graphql.uri, UpsertWorksheetPageAnnotationDocument, {
        worksheetPageVersionId,
        worksheetSetAssignmentId,
        garageUserId: userId,
        role,
        drawing,
        textboxes,
      }),
  });
}
