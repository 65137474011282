import { GetAssignmentDetailDocument } from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetAssignmentDetail({
  garageClassroomSessionId,
  garageUserId,
  worksheetSetVersionId,
  enabled,
}: {
  garageClassroomSessionId: number;
  garageUserId: number;
  worksheetSetVersionId: number;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: [
      "getAssignmentDetail",
      garageClassroomSessionId,
      garageUserId,
      worksheetSetVersionId,
    ],
    queryFn: async () =>
      request(config.graphql.uri, GetAssignmentDetailDocument, {
        garageClassroomSessionId: garageClassroomSessionId,
        garageUserId: garageUserId,
        worksheetSetVersionId: worksheetSetVersionId,
      }),
    enabled,
  });
}
