import request from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import config from "@/lib/config";
import { GetStudentsByClassroomSessionDocument } from "@/graphql/generated/graphql";

export function useGetStudentsByClassroomSessionID({
  sessionId,
}: {
  sessionId: number;
}) {
  return useQuery({
    queryKey: ["getStudentsByClassroomSessionID", sessionId],
    queryFn: async () =>
      request(config.graphql.uri, GetStudentsByClassroomSessionDocument, {
        sessionId: sessionId,
      }),
  });
}
