import { hc } from "hono/client";
import { type ApiRoutes } from "@server/app";

import config from "@/lib/config";
import { GraphQLClient } from "graphql-request";

export const graphqlClient = new GraphQLClient(config.graphql.uri, {
  credentials: "include",
});

const client = hc<ApiRoutes>("/");

export const api = client.api;

export const UNAUTHORIZED_ERROR = "Unauthorized";
