import {
  GetWorksheetSetAssigmentDocument,
  InputMaybe,
  WorksheetSetAssignmentFilters,
} from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetWorksheetSetAssigment({
  where,
}: {
  where: InputMaybe<WorksheetSetAssignmentFilters> | undefined;
}) {
  return useQuery({
    queryKey: ["getWorksheetSetAssigment", where],
    queryFn: async () =>
      request(config.graphql.uri, GetWorksheetSetAssigmentDocument, {
        where: where,
      }),
  });
}
