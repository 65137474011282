import { createFileRoute, Link, redirect } from "@tanstack/react-router";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Spinner } from "@/components/ui/spinner";
import ErrorHandler from "@/components/error-handler";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { useGetStudentsByClassroomSessionID } from "@/lib/apis/v2/queries/getStudentsByClassroomSessionID";
import Breadcrumbs from "@/components/breadcrumbs";
import { Badge } from "@/components/ui/badge";
import { AddWorksheetSetAssignmentModal } from "@/components/add-worksheet-set-assigment-modal";

export const Route = createFileRoute("/_auth/teacher/session/$sessionId/")({
  component: () => <SessionStudentList />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
    if (
      context.auth.user?.role !== "teacher" &&
      context.auth.user?.role !== "ops"
    ) {
      throw redirect({ to: "/student" });
    }
  },
});

function SessionStudentList() {
  const { sessionId } = Route.useParams();

  const {
    data: dataStudent,
    isPending: pendingStudent,
    error: errorStudent,
  } = useGetStudentsByClassroomSessionID({
    sessionId: Number(sessionId),
  });

  if (errorStudent) {
    return <ErrorHandler error={errorStudent as Error} />;
  }

  if (pendingStudent) {
    return <Spinner />;
  }

  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <div className="grid gap-4">
            <div className="mx-auto grid w-full">
              <h2 className="text-lg font-semibold">
                Student list for the{" "}
                {dataStudent?.getStudentsByClassroomSession?.session?.title}
              </h2>
            </div>
            <nav
              className="grid gap-4 text-sm text-muted-foreground"
              x-chunk="dashboard-04-chunk-0"
            >
              <Link to="/teacher">
                <span className="flex items-center gap-2 text-sm">
                  <ArrowLeft /> Return to session list
                </span>
              </Link>
            </nav>
          </div>
          <div className="grid gap-6">
            <Breadcrumbs />
            <div>
              <div className="flex items-center justify-center">
                <h1 className="text-lg font-bold">Students</h1>
                <AddWorksheetSetAssignmentModal sessionId={Number(sessionId)} />
              </div>
            </div>
            <Table>
              <TableCaption>
                End of Students for the{" "}
                {dataStudent?.getStudentsByClassroomSession?.session?.title}
              </TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">ID</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Nickname</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {dataStudent &&
                  (
                    dataStudent.getStudentsByClassroomSession?.students ?? []
                  ).map((student) => (
                    <TableRow key={student?.id}>
                      <TableCell className="font-medium">
                        {student?.id}
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-1">
                          <div>{student?.name}</div>
                          {student?.is_trial && (
                            <Badge variant="default" className="mr-1 mb-1">
                              Trial
                            </Badge>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{student?.nickname}</TableCell>
                      <TableCell className="text-right">
                        <div className="flex gap-2 justify-end">
                          <Button variant="outline">
                            <Link
                              to="/teacher/session/$sessionId/student/$userId"
                              params={{
                                sessionId: String(sessionId),
                                userId: String(student?.id),
                              }}
                            >
                              View worksheets
                            </Link>
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </main>
    </div>
  );
}
