import { useGetWorksheetSetAssigment } from "@/lib/apis/v2/queries/getWorksheetSetAssigment";
import { createFileRoute } from "@tanstack/react-router";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  CellContext,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { WorksheetSetAssignmentWorksheetSetVersionRelation } from "@/graphql/generated/graphql";
import { AddWorksheetSetAssignmentModal } from "@/components/add-worksheet-set-assigment-modal";
import { EditWorksheetSetAssignmentModal } from "@/components/edit-worksheet-set-assigment-modal";
import { useMemo } from "react";

interface FlattenedData {
  id: number;
  garageUserId: number;
  garageClassroomSessionId: number;
  worksheetSetVersions: WorksheetSetAssignmentWorksheetSetVersionRelation[];
}

// Helper function to group data by garageUserId and garageClassroomSessionId
const groupBy = <T,>(
  array: T[],
  keyFn: (item: T) => string
): Record<string, T[]> => {
  return array.reduce(
    (result, item) => {
      const key = keyFn(item);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    },
    {} as Record<string, T[]>
  );
};

export const Route = createFileRoute("/_auth/operations/assignments/")({
  component: () => <OperationAssigmentsPage />,
});

function OperationAssigmentsPage() {
  const { data } = useGetWorksheetSetAssigment({ where: {} });

  // Type guard to ensure data is available
  const assignments = useMemo(() => data?.worksheetSetAssignment ?? [], [data]);

  // Group data by garageUserId and garageClassroomSessionId
  const groupedData = useMemo(
    () =>
      groupBy(
        assignments,
        (item) => `${item.garageUserId}-${item.garageClassroomSessionId}`
      ),
    [assignments]
  );

  // Flatten the grouped data to match the table format
  const flattenedData: unknown[] = useMemo(
    () =>
      Object.entries(groupedData).map(({ 1: items }) => {
        const { id, garageUserId, garageClassroomSessionId } = items[0];
        const worksheetSetVersions = items.map(
          (item) => item.worksheetSetVersion
        );
        return {
          id,
          garageUserId,
          garageClassroomSessionId,
          worksheetSetVersions,
        };
      }),
    [groupedData]
  );

  // Define table columns
  const columns = useMemo(
    () => [
      {
        accessorKey: "garageUserId",
        header: "Garage User ID",
      },
      {
        accessorKey: "garageClassroomSessionId",
        header: "Classroom Session ID",
      },
      {
        accessorKey: "worksheetSetVersion",
        header: "Worksheet Set Versions",
        cell: (props: CellContext<FlattenedData, unknown>) => (
          <ul className="flex flex-wrap max-w-max">
            {props?.row?.original?.worksheetSetVersions?.map(
              (version: WorksheetSetAssignmentWorksheetSetVersionRelation) => (
                <li key={version.id}>
                  <EditWorksheetSetAssignmentModal
                    assigmentId={props.row.original.id}
                    sessionId={props.cell.row.original.garageClassroomSessionId}
                    studentId={props.cell.row.original.garageUserId}
                    worksheetSetVersion={version}
                  />
                </li>
              )
            )}
          </ul>
        ),
      },
    ],
    []
  );

  // Set up the React Table instance
  const table = useReactTable({
    data: flattenedData as FlattenedData[],

    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="grid gap-4 m-6">
      <div className="flex justify-between">
        <h2 className="font-bold">Worksheet Set Assigments</h2>
        <AddWorksheetSetAssignmentModal />
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    style={{ width: `${header.getSize()}px` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default OperationAssigmentsPage;
