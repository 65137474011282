import {
  integer,
  text,
  pgTable,
  serial,
  primaryKey,
  boolean,
  time,
  json,
  timestamp,
  pgEnum,
  unique,
} from "drizzle-orm/pg-core";
import { relations } from "drizzle-orm";
import { createInsertSchema, createSelectSchema } from "drizzle-zod";

const enumToPgEnum = <T extends Record<string, any>>(
  myEnum: T
): [T[keyof T], ...T[keyof T][]] => {
  return Object.values(myEnum).map((value: any) => `${value}`) as any;
};

export enum GarageUserType {
  STUDENT = "student",
  PARENT = "parent",
  TEACHER = "teacher",
  OPS = "ops",
}
export const garageUserTypeEnum = pgEnum(
  "garageUserType",
  enumToPgEnum(GarageUserType)
);

export const gaudiaLevel = pgTable("gaudia_level", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
});

export const gaudiaLevelRelations = relations(gaudiaLevel, ({ many }) => ({
  edukitaLevels: many(edukitaLevel),
  books: many(book),
}));

export const edukitaLevel = pgTable("edukita_level", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  gaudiaLevelId: integer("gaudia_level_id").references(() => gaudiaLevel.id),
});

export const edukitaLevelRelations = relations(edukitaLevel, ({ one }) => ({
  gaudiaLevel: one(gaudiaLevel, {
    fields: [edukitaLevel.gaudiaLevelId],
    references: [gaudiaLevel.id],
  }),
}));

export const book = pgTable("book", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  gaudiaLevelId: integer("gaudia_level_id").references(() => gaudiaLevel.id),
});

export const bookRelations = relations(book, ({ one, many }) => ({
  gaudiaLevel: one(gaudiaLevel, {
    fields: [book.gaudiaLevelId],
    references: [gaudiaLevel.id],
  }),
  units: many(unit),
}));

export const unit = pgTable("unit", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  bookId: integer("book_id").references(() => book.id),
  edukitaLevelId: integer("edukita_level_id").references(() => edukitaLevel.id),
});

export const unitRelations = relations(unit, ({ one, many }) => ({
  book: one(book, {
    fields: [unit.bookId],
    references: [book.id],
  }),
  edukitaLevel: one(edukitaLevel, {
    fields: [unit.edukitaLevelId],
    references: [edukitaLevel.id],
  }),
  subunits: many(subunit),
}));

export const insertUnitSchema = createInsertSchema(unit);
export const selectUnitSchema = createSelectSchema(unit);

export const subunit = pgTable("subunit", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  unitId: integer("unit_id").references(() => unit.id),
  order: integer("order").notNull(),
});

export const subunitRelations = relations(subunit, ({ one, many }) => ({
  unit: one(unit, {
    fields: [subunit.unitId],
    references: [unit.id],
  }),
  worksheets: many(worksheet),
}));

export const insertSubunitSchema = createInsertSchema(subunit);
export const selectSubunitSchema = createSelectSchema(subunit);

// Worksheet sets can cross multiple units and subunits but renumber from 1 for each level
export const worksheetSet = pgTable("worksheet_set", {
  id: serial("id").primaryKey(),
  order: integer("order").notNull(),
  name: text("name").notNull(),
  description: text("description"),
  gaudiaLevelId: integer("gaudia_level_id").references(() => gaudiaLevel.id),
});

export const worksheetSetRelations = relations(
  worksheetSet,
  ({ one, many }) => ({
    gaudiaLevel: one(gaudiaLevel, {
      fields: [worksheetSet.gaudiaLevelId],
      references: [gaudiaLevel.id],
    }),
    worksheetSetItems: many(worksheetSetItem),
  })
);

export const insertWorksheetSetSchema = createInsertSchema(worksheetSet);
export const selectWorksheetSetSchema = createSelectSchema(worksheetSet);

export const worksheet = pgTable("worksheet", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  textIdentifier: text("text_identifier").notNull(),
  description: text("description"),
  bookOrder: integer("book_order").notNull(),
  subunitId: integer("subunit_id").references(() => subunit.id),
  filename: text("filename").notNull(),
  originalFilename: text("original_filename").notNull(),
  bookStartPage: integer("book_start_page").notNull(),
  bookEndPage: integer("book_end_page").notNull(),
  ageRangeStart: integer("start_age_range").notNull(),
  ageRangeEnd: integer("end_age_range").notNull(),
  schoolGradeStart: text("school_grade_start").notNull(),
  schoolGradeEnd: text("school_grade_end").notNull(),
});

export const worksheetRelations = relations(worksheet, ({ one, many }) => ({
  subunit: one(subunit, {
    fields: [worksheet.subunitId],
    references: [subunit.id],
  }),
  worksheetSetItems: many(worksheetSetItem),
}));

export const insertWorksheetSchema = createInsertSchema(worksheet);
export const selectWorksheetSchema = createSelectSchema(worksheet);

export const worksheetSetItem = pgTable("worksheet_set_item", {
  id: serial("id").primaryKey(),
  setId: integer("worksheet_set_id").references(() => worksheetSet.id),
  worksheetId: integer("worksheet_id").references(() => worksheet.id),
});

export const worksheetSetItemRelations = relations(
  worksheetSetItem,
  ({ one }) => ({
    worksheetSet: one(worksheetSet, {
      fields: [worksheetSetItem.setId],
      references: [worksheetSet.id],
    }),
    worksheet: one(worksheet, {
      fields: [worksheetSetItem.worksheetId],
      references: [worksheet.id],
    }),
  })
);

export const insertWorksheetSetItemSchema =
  createInsertSchema(worksheetSetItem);
export const selectWorksheetSetItemSchema =
  createSelectSchema(worksheetSetItem);

export const users = pgTable(
  "users",
  {
    id: serial("id").primaryKey(),
    fullName: text("full_name"),
    preferredName: text("preferred_name"),
    garageId: integer("garage_id").notNull(),
    garageUserType: garageUserTypeEnum("garageUserType").default(
      GarageUserType.STUDENT
    ),
    username: text("username").notNull().unique(),
    teacher: boolean("teacher").notNull().default(false),
    ops: boolean("ops").notNull().default(false),
  },
  (t) => ({
    unq: unique().on(t.garageId, t.garageUserType),
  })
);

export const usersRelations = relations(users, ({ many }) => ({
  classroomSessionParticipants: many(classroomSessionParticipants),
  worksheetSetAssignments: many(worksheetSetAssignment),
}));

export const insertUserSchema = createInsertSchema(users);
export const selectUserSchema = createSelectSchema(users);

export const classroomSession = pgTable("classroom_session", {
  id: serial("id").primaryKey(),
  name: text("name").notNull(),
  startAt: time("start_at").notNull(),
  endAt: time("end_at").notNull(),
});

export const classroomSessionRelations = relations(
  classroomSession,
  ({ many }) => ({
    classroomSessionParticipants: many(classroomSessionParticipants),
  })
);

export const classroomSessionParticipants = pgTable(
  "classroom_session_participants",
  {
    classroomSessionId: integer("classroom_session_id").references(
      () => classroomSession.id
    ),
    userId: integer("user_id").references(() => users.id),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.userId, t.classroomSessionId] }),
  })
);

export const classroomSessionParticipantsRelations = relations(
  classroomSessionParticipants,
  ({ one }) => ({
    classroomSession: one(classroomSession, {
      fields: [classroomSessionParticipants.classroomSessionId],
      references: [classroomSession.id],
    }),
    user: one(users, {
      fields: [classroomSessionParticipants.userId],
      references: [users.id],
    }),
  })
);

export const worksheetSetAssignment = pgTable(
  "worksheet_set_assignment",
  {
    userId: integer("user_id").references(() => users.id),
    worksheetSetId: integer("worksheet_set_id").references(
      () => worksheetSet.id
    ),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.userId, t.worksheetSetId] }),
  })
);

export const worksheetSetAssignmentRelations = relations(
  worksheetSetAssignment,
  ({ one }) => ({
    user: one(users, {
      fields: [worksheetSetAssignment.userId],
      references: [users.id],
    }),
    worksheetSet: one(worksheetSet, {
      fields: [worksheetSetAssignment.worksheetSetId],
      references: [worksheetSet.id],
    }),
  })
);

export const worksheetAttempt = pgTable("worksheet_attempt", {
  id: serial("id").primaryKey(),
  userId: integer("user_id").references(() => users.id),
  worksheetId: integer("worksheet_id").references(() => worksheet.id),
  startedAt: timestamp("started_at", {
    withTimezone: false,
    mode: "date",
  })
    .notNull()
    .defaultNow(),
  completedAt: timestamp("ended_at", { withTimezone: false, mode: "date" }),
  completed: boolean("completed").notNull().default(false),
  markedCorrect: boolean("marked_correct").notNull().default(false),
  teacherComments: text("teacher_comments"),
  drawing: json("drawing"),
  textboxes: json("textboxes"),
});

export const worksheetSetCompletionEvent = pgTable(
  "worksheet_set_completion_event",
  {
    id: serial("id").primaryKey(),
    userId: integer("user_id").references(() => users.id),
    worksheetSetId: integer("worksheet_set_id").references(
      () => worksheetSet.id
    ),
    garageSessionId: integer("garage_session_id").notNull(),
    assignedAt: timestamp("assigned_at", {
      withTimezone: false,
      mode: "date",
    })
      .notNull()
      .defaultNow(),
  }
);

