import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useUpdateWorksheetSetAssignment } from "@/lib/apis/v2/mutations/updateWorksheetSetAssigment";
import { DialogClose, DialogTrigger } from "@radix-ui/react-dialog";
import WorksheetSetVersionListCombobox from "./worksheet-set-version-list-combobox";
import { Label } from "./ui/label";
import StudentListCombobox from "./student-list-combobox";
import { Badge } from "./ui/badge";
import { WorksheetSetAssignmentWorksheetSetVersionRelation } from "@/graphql/generated/graphql";
import { toast } from "sonner";

interface EditWorksheetSetAssignmentModalProps {
  assigmentId: number;
  sessionId?: number;
  studentId?: number;
  worksheetSetVersion?: WorksheetSetAssignmentWorksheetSetVersionRelation;
}

export function EditWorksheetSetAssignmentModal(
  props: EditWorksheetSetAssignmentModalProps
) {
  const [userId, setUserId] = useState<number | null>(props?.studentId ?? null);
  const [worksheetSetVersionId, setWorksheetSetVersionId] = useState<
    number | null
  >(props?.worksheetSetVersion?.id ?? null);
  const [sessionId, setSessionId] = useState<number | null>(
    props?.sessionId ?? null
  );
  const [modeInputStudent, setModeInputStudent] = useState<"id" | "search">(
    "id"
  );

  const updateMutation = useUpdateWorksheetSetAssignment();

  const handleSave = () => {
    if (!userId || !worksheetSetVersionId) {
      return;
    }
    try {
      updateMutation.mutate({
        where: {
          id: {
            eq: Number(props.assigmentId),
          },
        },
        set: {
          garageClassroomSessionId: sessionId,
          garageUserId: userId,
          worksheetSetVersionId: worksheetSetVersionId,
        },
      });
      toast.success("Saved!");
      window.location.reload();
    } catch (error) {
      toast.error("Error saving worksheet set assignment");
    }
  };

  return (
    <Dialog>
      <div className="w-full flex justify-start">
        <DialogTrigger asChild>
          <Button variant="link" size="sm">
            <Badge
              variant="outline"
              className="mr-1 mb-1 cursor-pointer hover:bg-blue-100"
            >
              {props?.worksheetSetVersion?.worksheetSet?.name} (Version{" "}
              {props?.worksheetSetVersion?.version})
            </Badge>
          </Button>
        </DialogTrigger>
      </div>
      <DialogContent>
        {/* Header */}
        <DialogHeader>
          <DialogTitle>Edit Assignment</DialogTitle>
        </DialogHeader>
        {/* Add form fields to edit assignment details */}
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Session ID
            </Label>
            <Input
              className="w-full col-span-3"
              type="number"
              placeholder="Garage Session ID"
              value={Number(sessionId)}
              onChange={(e) => setSessionId(Number(e.currentTarget.value))}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              {modeInputStudent === "id" ? "Student ID" : "Search Student"}
            </Label>
            <div className="w-full col-span-3 flex gap-2 items-center">
              <div className="grid gap-1">
                {modeInputStudent === "search" ? (
                  <StudentListCombobox
                    selectedId={Number(userId)}
                    onSelect={(id) => {
                      setUserId(id);
                      setModeInputStudent("id");
                    }}
                  />
                ) : (
                  <Input
                    className="w-full col-span-3"
                    type="number"
                    value={Number(userId)}
                    placeholder="Garage Student ID"
                    onChange={(e) =>
                      setSessionId(Number(e.currentTarget.value))
                    }
                  />
                )}
                <button
                  className="text-blue-400 text-xs text-start"
                  onClick={() => {
                    setModeInputStudent(
                      modeInputStudent === "id" ? "search" : "id"
                    );
                  }}
                >
                  {modeInputStudent === "search"
                    ? "Input ID"
                    : "Search Student"}
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Worksheet Set
            </Label>
            <WorksheetSetVersionListCombobox
              selectedId={Number(worksheetSetVersionId)}
              onSelect={(id) => setWorksheetSetVersionId(id)}
            />
          </div>
        </div>
        {/* Footer */}
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              type="submit"
              disabled={!userId || !worksheetSetVersionId || !sessionId}
              onClick={handleSave}
            >
              Save changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
