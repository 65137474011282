import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import { useGetWorksheetSets } from "@/lib/apis/v2/queries/getWorksheetSets";
import { createFileRoute } from "@tanstack/react-router";
export const Route = createFileRoute("/_auth/operations/worksheet-sets/")({
  component: () => <WorksheetPreview />,
});

function WorksheetPreview() {
  // const { worksheetId } = Route.useParams();

  const { data } = useGetWorksheetSets();

  return (
    <div className="m-4 p-6 border border-blue-500 rounded-lg">
      <Accordion type="multiple" className="w-full">
        {data?.worksheetSet?.map((item) => (
          <AccordionItem value={String(item.id)}>
            <AccordionTrigger>
              <div className="grid text-start">
                <div className="">{`ID: ${item.id} - Worksheet: ${item.name} - Level: ${item.gaudiaLevelId}`}</div>
                <div className="text-xs">{`${item.description}`}</div>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="border border-pink-500 rounded-lg p-4 grid gap-4">
                <div>
                  <h3>Versions</h3>
                </div>
                <div className="grid divide-y gap-2">
                  {item.versions.map((version) => (
                    <div className="grid text-start">
                      <div>{`ID: ${version.id} - Version: ${version.version}`}</div>
                    </div>
                  ))}
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}
