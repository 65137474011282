import { GetMarkingSessionSummaryDocument } from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetMarkingSessionSummary({
  garageClassroomSessionId,
  garageUserId,
  worksheetSetVersionId,
}: {
  garageClassroomSessionId: number;
  garageUserId: number;
  worksheetSetVersionId: number;
}) {
  return useQuery({
    queryKey: [
      "getMarkingSessionSummary",
      garageClassroomSessionId,
      garageUserId,
      worksheetSetVersionId,
    ],
    queryFn: async () =>
      request(config.graphql.uri, GetMarkingSessionSummaryDocument, {
        garageClassroomSessionId: garageClassroomSessionId,
        garageUserId: garageUserId,
        worksheetSetVersionId: worksheetSetVersionId,
      }),
  });
}
