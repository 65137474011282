import {
  GetMarkingSessionOverrideDocument,
  InputMaybe,
  MarkingSessionOverrideFilters,
} from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetMarkingSessionOverride({
  where,
  enabled,
}: {
  where: InputMaybe<MarkingSessionOverrideFilters> | undefined;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: ["getMarkingSessionOverride", where],
    queryFn: async () =>
      request(config.graphql.uri, GetMarkingSessionOverrideDocument, {
        where: where,
      }),
    enabled: enabled,
  });
}
