import { DraggableTextInput } from "@server/shared-types.ts";
import { useState } from "react";

export const useTextInputs = (
  initialInputs: {
    teacher: DraggableTextInput[];
    student: DraggableTextInput[];
  } = {
    student: [],
    teacher: [],
  }
) => {
  const [textInputs, setTextInputs] = useState<{
    student: DraggableTextInput[];
    teacher: DraggableTextInput[];
  }>(initialInputs);

  const updateText = (
    id: string,
    text: string,
    role: "student" | "teacher"
  ) => {
    const newInputs = [...textInputs[role]];
    const index = newInputs.findIndex((input) => input.id === id);
    newInputs[index].text = text;
    setTextInputs({
      ...textInputs,
      [role]: newInputs,
    });
  };

  const removeText = (id: string, role: "student" | "teacher") => {
    const newInputs = textInputs[role].filter((input) => input.id !== id);
    setTextInputs({
      ...textInputs,
      [role]: newInputs,
    });
  };

  const updateDragPosition = (
    id: string,
    x: number,
    y: number,
    role: "student" | "teacher"
  ) => {
    const newInputs = [...textInputs[role]];
    const index = newInputs.findIndex((input) => input.id === id);
    newInputs[index].x = x;
    newInputs[index].y = y;
    setTextInputs({
      ...textInputs,
      [role]: newInputs,
    });
  };

  return {
    textInputs,
    updateText,
    removeText,
    updateDragPosition,
    setTextInputs,
  };
};
