import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import { GetWorksheetSetVersionDetailDocument } from "../../../../graphql/generated/graphql";
import request from "graphql-request";

export function useGetWorksheetSetVersionDetail({
  worksheetSetId,
  worksheetSetVersionId,
  enabled,
}: {
  worksheetSetId: number;
  worksheetSetVersionId: number;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: [
      "getWorksheetSetVersionDetail",
      worksheetSetId,
      worksheetSetVersionId,
    ],
    queryFn: async () =>
      request(config.graphql.uri, GetWorksheetSetVersionDetailDocument, {
        worksheetSetId: worksheetSetId,
        worksheetSetVersionId: worksheetSetVersionId,
      }),
    enabled,
  });
}
