import { GetWorksheetSetsDocument } from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetWorksheetSets() {
  return useQuery({
    queryKey: ["getWorksheetSets"],
    queryFn: async () => request(config.graphql.uri, GetWorksheetSetsDocument),
  });
}
