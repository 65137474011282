import {
  GetMarkingSessionsQuery,
  GetWorksheetVersionQuery,
} from "@/graphql/generated/graphql";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const PASSING_THRESHOLD = 0.75;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isStaging() {
  return window && window.location.hostname !== "math.edukita.com";
}

export function unique<T>(arr: T[]) {
  return Array.from(new Set(arr));
}

// Function to determine if the worksheet versions are passed
export function getWorksheetVersionPassFailMap({
  markingSessionData,
  worksheetVersionData,
}: {
  markingSessionData: GetMarkingSessionsQuery | undefined;
  worksheetVersionData: GetWorksheetVersionQuery | undefined;
}): Record<number, boolean> {
  const result: Record<number, boolean> = {};

  if (!markingSessionData || !worksheetVersionData) {
    return result;
  }

  const { markingSession } = markingSessionData;
  const { worksheetVersion } = worksheetVersionData;

  // Get all marked questions from the marking session
  const markedQuestions = markingSession?.[0]?.markedQuestions ?? [];

  // Create a map to track scores by worksheetPageVersionId
  const questionScoreMap = markedQuestions.reduce(
    (acc: Record<number, { points: number; totalPoints: number }>, markedQuestion) => {
      const pageVersionId = markedQuestion.worksheetPageQuestion?.worksheetPageVersion?.id;
      const scoredPoints = markedQuestion.points ?? 0;
      const totalPoints = markedQuestion.worksheetPageQuestion?.totalPoints ?? 0;

      if (pageVersionId) {
        if (!acc[pageVersionId]) {
          acc[pageVersionId] = { points: 0, totalPoints: 0 };
        }
        acc[pageVersionId].points += scoredPoints;
        acc[pageVersionId].totalPoints += totalPoints;
      }

      return acc;
    },
    {}
  );

  // Iterate over each worksheetVersion and calculate the total score for all worksheetPageVersions
  worksheetVersion?.forEach((version) => {
    const versionId = version.id;
    const worksheetVersionItems = version.worksheetVersionItems ?? [];

    let totalScoredPoints = 0;
    let totalPossiblePoints = 0;

    worksheetVersionItems.forEach((versionItem) => {
      const pageVersionId = versionItem?.worksheetPageVersion?.id;

      if (pageVersionId && questionScoreMap[pageVersionId]) {
        totalScoredPoints += questionScoreMap[pageVersionId].points;
        totalPossiblePoints += questionScoreMap[pageVersionId].totalPoints;
      }
    });

    // If no possible points are found, mark the version as failed
    if (totalPossiblePoints === 0) {
      result[versionId] = false;
    } else {
      // Check if the worksheet version is passed based on the threshold
      const scorePercentage = totalScoredPoints / totalPossiblePoints;
      result[versionId] = scorePercentage >= PASSING_THRESHOLD;
    }
  });


  return result;
}
