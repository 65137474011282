import {
  insertUnitSchema,
  insertSubunitSchema,
  insertWorksheetSetSchema,
  insertWorksheetSetItemSchema,
} from "./db/v1/schema";
import { z } from "zod";

export const createUnitSchema = insertUnitSchema.omit({
  id: true,
});

export type CreateUnit = z.infer<typeof createUnitSchema>;

export const createSubunitSchema = insertSubunitSchema.omit({
  id: true,
});

export type CreateSubunit = z.infer<typeof createSubunitSchema>;

export const createWorksheetSet = insertWorksheetSetSchema.omit({
  id: true,
});

export type CreateWorksheetSet = z.infer<typeof createWorksheetSet>;

export type CreateWorksheetSetItem = z.infer<
  typeof insertWorksheetSetItemSchema
>;


export interface GroupedWorksheetSetAssignments {
  id: number;
  order: number;
  name: string;
  description: string | null;
  level: Book;
  book: Book;
  worksheets: Worksheet[];
}

export interface Book {
  id: number;
  name: string;
}

export interface Worksheet {
  id: number;
  name: string;
  order: number;
  subunit: Subunit;
}

export interface Subunit {
  id: number;
  name: string;
  unit: Book;
}

export interface Drawing {
  stroke: number;
  color: string;
  mode: number;
  path: Array<number[]>;
}

export interface Textbox {
  text: string;
  fontSize: number;
  position: {
    x: number;
    y: number;
  };
}

export interface DraggableTextInput {
  id: string;
  x: number;
  y: number;
  text: string;
  fontSize: number;
}

export interface PaulaSessions {
  schedules: Schedule[];
}

export interface Schedule {
  link: string;
  btsId: string;
  courseId: string;
  useBts: boolean;
  id: string;
  title: string;
  grade: string;
  topic: string;
  startTime: Date;
  endTime: Date;
  teacher: PaulaTeacher;
  elective: null;
  learningMaterials: PaulaLearningMaterial[];
  learningUnit: PaulaLearningUnit | null;
  btsSeatMapAccessCode: string;
}

export interface PaulaLearningMaterial {
  id: string;
  deliveryTypes: DeliveryType[];
  learningCycle: string;
  learningUnitId: string;
  link: string;
  sequence: string;
  title: string;
  type: string;
}

export type DeliveryType = "hybrid" | "online";
export interface PaulaLearningUnit {
  id: string;
  theme: string;
  topic: string;
  levels: string[];
}

export interface PaulaTeacher {
  id: string;
  name: string;
  from: string;
  image: string;
  email: string;
}

export interface PaulaSessionStudentResponse {
  students: PaulaStudent[];
}

export interface PaulaStudent {
  id: string;
  name: string;
  activeSubscription: boolean;
  nickname: string;
  isTrial: boolean;
}

export type Pair<T, K> = [T, K];
export type Pairs<T, K> = Pair<T, K>[];

export enum MODES {
  PAN = 0,
  PEN = 1,
  LINE = 2,
  RECT = 3,
  CIRCLE = 4,
  TEXT = 5,
  ERASER = 6,
}

export type DrawingSettings = {
  stroke: number;
  color: string;
  fontSize: number;
  mode: MODES;
};

export interface DrawingHistory extends DrawingSettings {
  path: Pairs<number, number>;
}
