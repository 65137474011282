import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useGetStudentPaginated } from "@/lib/apis/v2/queries/getStudentPaginated";
import { useEffect, useState } from "react";
import { CommandLoading } from "cmdk";
import { ScrollArea } from "./ui/scroll-area";

interface StudentListComboboxProps {
  selectedId?: number;
  onSelect: (id: number) => void;
}

const StudentListCombobox = ({ selectedId, onSelect }: StudentListComboboxProps) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<null | {
    id: number;
    name: string;
  }>(null);
  const [searchText, setSearchText] = useState("");
  const { data, isPending, error } = useGetStudentPaginated({
    name: searchText,
    perPage: 100,
  });

  useEffect(() => {
    if (selectedId) {
      const selectedStudent = data?.getStudentPaginated?.result?.find(
        (student) => Number(student?.student?.id) === selectedId
      );
      if (selectedStudent) {
        setSelectedItem({
          id: Number(selectedStudent?.student?.id),
          name: String(selectedStudent?.student?.name),
        });
      }
    } else {
      setSelectedItem(null);
    }
  }, [selectedId, data]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {selectedItem ? selectedItem?.name : "Select student..."}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput
            placeholder="Search framework..."
            className="h-9"
            onChangeCapture={(e) => {
              setSearchText(e.currentTarget.value);
            }}
          />
          <CommandList>
            {error && (
              <CommandEmpty>Error while fetching student data</CommandEmpty>
            )}
            {isPending && (
              <CommandLoading>
                <div className="flex p-2 items-center text-sm">
                  Fetching data...
                </div>
              </CommandLoading>
            )}
            {!isPending && data?.getStudentPaginated?.result?.length === 0 && (
              <CommandEmpty>
                <div className="flex p-2 items-center text-sm">
                  No student found.
                </div>
              </CommandEmpty>
            )}
          <ScrollArea className="h-72">
            {!isPending &&
              !!data?.getStudentPaginated?.result?.length &&
              data?.getStudentPaginated?.result?.map((item) => (
                <div
                  className="flex items-center p-2 cursor-pointer text-sm hover:bg-gray-100"
                  key={item?.student?.id}
                  onClick={() => {
                    setSelectedItem({
                      id: Number(item?.student?.id),
                      name: String(item?.student?.name),
                    });
                    onSelect(Number(item?.student?.id));
                    setOpen(false);
                  }}
                >
                  {item?.student?.name}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      selectedItem?.id === item?.student?.id
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </div>
              ))}
</ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default StudentListCombobox;
