import {
  GetMarkingSessionsDocument,
  InputMaybe,
  MarkingSessionFilters,
} from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetMarkingSessions({
  where,
  enabled,
}: {
  where: InputMaybe<MarkingSessionFilters> | undefined;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: ["getMarkingSessions", where],
    queryFn: async () =>
      request(config.graphql.uri, GetMarkingSessionsDocument, { where: where }),
    enabled,
  });
}
