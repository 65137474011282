import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useUpsertMarkingSessionOverride } from "@/lib/apis/v2/mutations/upsertMarkingSessionOverride";
import { toast } from "sonner";
import { useGetMarkingSessionOverride } from "@/lib/apis/v2/queries/getMarkingSessionOverride";
import { useQueryClient } from "@tanstack/react-query";

interface MarkingSessionOverrideModalProps {
  markingSessionId: number;
  worksheetSetVersionId: number;
  worksheetVersionId: number;
  worksheetName?: string;
}

const MarkingSessionOverrideModal = ({
  markingSessionId,
  worksheetSetVersionId,
  worksheetVersionId,
  worksheetName,
}: MarkingSessionOverrideModalProps) => {
  const [remarks, setRemarks] = useState<string | undefined>(undefined);
  const [overrideStatus, setOverrideStatus] = useState<string | undefined>(
    undefined
  );

  const queryClient = useQueryClient();

  const { data } = useGetMarkingSessionOverride({
    where: {
      markingSessionId: {
        eq: markingSessionId,
      },
      worksheetSetVersionId: {
        eq: worksheetSetVersionId,
      },
      worksheetVersionId: {
        eq: worksheetVersionId,
      },
    },
    enabled: true,
  });

  const upsertMarkingSessionOverrideMutation =
    useUpsertMarkingSessionOverride();

  const handleSave = async() => {
    await upsertMarkingSessionOverrideMutation.mutateAsync({
      markingSessionId,
      worksheetSetVersionId,
      worksheetVersionId,
      overrideStatus: overrideStatus!,
      remarks: String(remarks),
    });
    queryClient.invalidateQueries({ queryKey: ["getMarkingSessionOverride"] });
    toast.success("Overrided!");
  };

  useEffect(() => {
    if (data) {
      setOverrideStatus(data?.markingSessionOverride?.[0]?.overrideStatus);
      setRemarks(data?.markingSessionOverride?.[0]?.remarks ?? "");
    }
  }, [data]);

  return (
    <Dialog>
      <div className="w-full flex justify-end">
        <DialogTrigger asChild>
          <Button variant="secondary" size="sm">
            Override Result
          </Button>
        </DialogTrigger>
      </div>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Override Worksheet Result</DialogTitle>
          <DialogDescription>
            Use this form to override the result for worksheet: {worksheetName}.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {/* OverrideStatus Selection */}
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Override Status
            </Label>
            <div className="w-full col-span-3">
              <Select
                value={overrideStatus}
                onValueChange={(value) => setOverrideStatus(value)}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select new status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Available Status</SelectLabel>
                    <SelectItem
                      className="hover:bg-slate-200 cursor-pointer"
                      value="pass"
                    >
                      Pass
                    </SelectItem>
                    <SelectItem
                      className="hover:bg-slate-200 cursor-pointer"
                      value="fail"
                    >
                      Fail
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          {/* Remarks Input */}
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Remarks
            </Label>
            <Input
              className="w-full col-span-3"
              type="text"
              placeholder="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.currentTarget.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              type="submit"
              disabled={!remarks || !overrideStatus}
              onClick={handleSave}
            >
              Save changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MarkingSessionOverrideModal;
