import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import { GetWorksheetPageVersionDetailDocument } from "../../../../graphql/generated/graphql";
import request from "graphql-request";

export function useGetWorksheetPageVersionDetail({
  worksheetPageVersionId,
  markingSessionId,
  worksheetSetAssignmentId,
  enabled,
}: {
  worksheetPageVersionId: number | null;
  markingSessionId: number | null;
  worksheetSetAssignmentId: number | null;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: ["getWorksheetPageVersionDetail", worksheetPageVersionId],
    queryFn: async () =>
      request(config.graphql.uri, GetWorksheetPageVersionDetailDocument, {
        worksheetPageVersionId: Number(worksheetPageVersionId),
        markingSessionId: Number(markingSessionId),
        worksheetSetAssignmentId: Number(worksheetSetAssignmentId),
      }),
    enabled,
  });
}
