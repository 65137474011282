import { useGetStudentsByClassroomSessionID } from "@/lib/apis/v2/queries/getStudentsByClassroomSessionID";
import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";

interface StudentList {
  sessionId: number;
  activeStudentId?: number;
}

const StudentList = ({ sessionId, activeStudentId }: StudentList) => {
  const { data, isPending, error } = useGetStudentsByClassroomSessionID({
    sessionId,
  });

  if (isPending) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Something went wrong while fetching Student List</div>;
  }
  return (
    <div className="grid gap-3">
      <h2 className="font-bold">
        Class: {data?.getStudentsByClassroomSession?.session?.title}
      </h2>
      <ul className="grid gap-2">
        {data?.getStudentsByClassroomSession?.students?.map((student) => (
          <Link
            className={cn("cursor-pointer hover:text-blue-500", {
              "text-blue-500": activeStudentId === student?.id,
            })}
            key={student?.id}
            to="/teacher/session/$sessionId/student/$userId"
            params={{
              sessionId: String(sessionId),
              userId: String(student?.id),
            }}
          >
            <li key={student?.id}>{student?.name}</li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default StudentList;
