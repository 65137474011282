import { cn } from "@/lib/utils";
import { useRouterState } from "@tanstack/react-router";

function Breadcrumbs() {
  const route = useRouterState();
  // const matchRoute = useMatchRoute();
  const segments = route.location.pathname.split("/").filter(Boolean);

  const breadcrumbLinks = segments.map((segment, index) => {
    const path = "/" + segments.slice(0, index + 1).join("/");
    const isLast = index === segments.length - 1;

    // Check if the current path has a page using matchRoute from TanStack Router
    // const matchedRoute = matchRoute({ to: path });
    const hasPage = true;

    return (
      <li key={path} className="inline-block">
        {hasPage ? (
          <a
            href={path}
            className={cn("text-sm font-medium", {
              "text-gray-500": isLast,
              "hover:underline": !isLast,
            })}
            aria-current={isLast ? "page" : undefined}
          >
            {segment.charAt(0).toUpperCase() + segment.slice(1)}{" "}
            {/* Capitalize segment */}
          </a>
        ) : (
          <span className="text-gray-500">
            {segment.charAt(0).toUpperCase() + segment.slice(1)}{" "}
            {/* Display as non-clickable text */}
          </span>
        )}
        {!isLast && <span className="mx-2 text-sm text-gray-400">/</span>}
      </li>
    );
  });

  return (
    <nav aria-label="breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="inline-block">
          <a href="/" className="text-sm font-medium hover:underline">
            Home
          </a>
          {segments.length > 0 && (
            <span className="mx-2 text-sm text-gray-400">/</span>
          )}
        </li>
        {breadcrumbLinks}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
