import { createContext, useContext } from "react";

export interface AuthContext {
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  getMeAndSetUser: () => Promise<void>;
  user: {
    id: number;
    name: string;
    email: string;
    role: string;
  } | null;
}

export const AuthContext = createContext<AuthContext | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
