import { useEffect, useRef, useCallback, useReducer, useState } from "react";
import { CANVAS_WIDTH, DEFAULT_FONT_SIZE, PAN_LIMIT } from "./constants";
import {
  MODES,
  DrawingSettings,
  Pair,
  Pairs,
  DrawingHistory,
  DraggableTextInput,
  Textbox,
} from "@server/shared-types";
import exportIcon from "@/assets/export.svg";
import { ArrowBigRightDash } from "lucide-react";
import {
  cn,
  // getWorksheetVersionPassFailMap,
  PASSING_THRESHOLD,
} from "@/lib/utils";
import { Link, useNavigate } from "@tanstack/react-router";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import EditableText from "../editable-text";
import useNavigatorOnline from "@/lib/useNavigatorOnline.ts";
import { useDrawingSettings, useTextInputs } from "@/components/canvas/hooks";
import CanvasToolbar from "@/components/canvas/canvas-toolbar.tsx";
import { useCanvasActions } from "@/components/canvas/hooks/useCanvasActions.tsx";
import { useCanvasEvents } from "@/components/canvas/hooks/useCanvasEvents.tsx";
import { Skeleton } from "@/components/ui/skeleton";
import WorksheetSetThumbnails from "../worksheet-set-thumbnails";
import clsx from "clsx";
import { useUpsertWorksheetPageAnnotation } from "@/lib/apis/v2/mutations/upsertWorksheetPageAnnotation";
import { AnnotationRole } from "@server/db/v2/schema";
import { useGetWorksheetVersion } from "@/lib/apis/v2/queries/getWorksheetVersion";
import { useAuth } from "@/lib/useAuth";
import { useGetWorksheetPageVersionDetail } from "@/lib/apis/v2/queries/getWorksheetPageVersionDetail";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash-es";
import { buttonVariants } from "../ui/button";
import { useUpsertMarkingSession } from "@/lib/apis/v2/mutations/upsertMarkingSession";
import { useGetAssignmentDetail } from "@/lib/apis/v2/queries/getAssignmentDetail";
import { useGetMarkingSessions } from "@/lib/apis/v2/queries/getMarkingSessions";
import { useGetStudentsByClassroomSessionID } from "@/lib/apis/v2/queries/getStudentsByClassroomSessionID";
// import { useGetMarkingSessionOverride } from "@/lib/apis/v2/queries/getMarkingSessionOverride";
import { Spinner } from "../ui/spinner";

interface CanvasProps {
  width: number;
  height: number;
  setVersionId: string;
  sessionId: string;
  worksheet: {
    currentWorksheetVersionId: string;
    isLastWorksheet?: boolean | null;
    nextWorksheetVersionId?: number | null;
  };
  teacherView: boolean;
  studentId?: string;
}

const WorksheetStudentNavigation = ({
  sessionId,
  currentActiveStudentId,
}: {
  sessionId: number;
  currentActiveStudentId: number;
}) => {
  const { data } = useGetStudentsByClassroomSessionID({ sessionId });
  const navigate = useNavigate();

  const handleNavigate = (value: string) => {
    if (currentActiveStudentId !== Number(value)) {
      navigate({
        to: `/teacher/session/${sessionId}/student/${value}`,
      });
    }
  };
  return (
    <Select
      value={String(currentActiveStudentId)}
      onValueChange={handleNavigate}
    >
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select student" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Navigate to Student Worksheet</SelectLabel>
          {data?.getStudentsByClassroomSession?.students?.map((student) => (
            <SelectItem
              className="hover:bg-slate-200 cursor-pointer"
              key={student?.id}
              value={String(student?.id)}
            >
              {student?.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

const Canvas = ({ ...rest }: CanvasProps) => {
  const auth = useAuth();
  const width = Math.min(rest.width, PAN_LIMIT);
  const height = rest.height;
  const canvas = useRef<HTMLCanvasElement>(null);
  const context = useRef<CanvasRenderingContext2D | null>(null);
  const importInput = useRef(null);

  let role = AnnotationRole.STUDENT;
  let siblingRole = AnnotationRole.TEACHER;

  if (rest.teacherView) {
    role = AnnotationRole.TEACHER;
    siblingRole = AnnotationRole.STUDENT;
  }

  const [, render] = useReducer((prev) => !prev, false);
  const [showAnswerKey, setShowAnswerKey] = useState(false);
  const [loadingStates, setLoadingStates] = useState<boolean[]>([]);
  const [topNavHeight, setTopNavHeight] = useState(0);
  const activePageIndex = useRef(0);
  const topNavRef = useRef<HTMLDivElement>(null);
  const pageRefs = useRef<HTMLDivElement[]>([]);

  const [pointsState, setPointsState] = useState<Record<number, number>>({});
  const handlePointsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    questionId: number,
    totalPoints: number
  ) => {
    const points = Math.min(
      Math.max(parseInt(e.target.value, 10) || 0, 0),
      totalPoints
    );
    setPointsState((prev) => ({ ...prev, [questionId]: points }));
  };

  const { data, isPending, refetch } = useGetWorksheetVersion({
    where: {
      id: {
        eq: Number(rest.worksheet.currentWorksheetVersionId),
      },
    },
    enabled: !isEmpty(rest.worksheet.currentWorksheetVersionId),
  });

  const { data: dataAssigment } = useGetAssignmentDetail({
    garageClassroomSessionId: Number(rest.sessionId),
    garageUserId: rest?.teacherView
      ? Number(rest.studentId)
      : Number(auth.user?.id),
    worksheetSetVersionId: Number(rest.setVersionId),
    enabled: !!rest.sessionId && !!rest.setVersionId,
  });

  const { data: dataMarkingSession } = useGetMarkingSessions({
    where: {
      worksheetSetAssignmentId: {
        eq: Number(dataAssigment?.getAssignmentDetail?.id),
      },
    },
    enabled: !!dataAssigment?.getAssignmentDetail?.id,
  });

  // const markingResult = getWorksheetVersionPassFailMap({
  //   markingSessionData: dataMarkingSession,
  //   worksheetVersionData: data,
  // });

  const { data: dataPageDetail, refetch: refetchPageDetail } =
    useGetWorksheetPageVersionDetail({
      worksheetPageVersionId: Number(
        data?.worksheetVersion?.[0]?.worksheetVersionItems?.[
          activePageIndex.current
        ]?.worksheetPageVersion?.id
      ),
      markingSessionId: Number(dataMarkingSession?.markingSession?.[0]?.id),
      worksheetSetAssignmentId: Number(dataAssigment?.getAssignmentDetail?.id),
      enabled:
        !!data?.worksheetVersion?.[0]?.worksheetVersionItems?.[
          activePageIndex.current
        ]?.worksheetPageVersion?.id &&
        !!dataAssigment?.getAssignmentDetail?.id,
    });

  // const { data: dataMarkingSessionOverride } = useGetMarkingSessionOverride({
  //   where: {
  //     worksheetSetVersionId: {
  //       eq: Number(rest.setVersionId),
  //     },
  //     worksheetVersionId: {
  //       eq: Number(rest.worksheet.currentWorksheetVersionId),
  //     },
  //   },
  //   enabled: !!rest.setVersionId && !!rest.worksheet.currentWorksheetVersionId,
  // });

  useEffect(() => {
    if (rest.worksheet.currentWorksheetVersionId) {
      refetch();
    }
  }, [rest.worksheet.currentWorksheetVersionId]);

  useEffect(() => {
    // Initialize loading and image loaded states for each image
    setLoadingStates(
      Array(
        data?.worksheetVersion?.[
          data?.worksheetVersion?.findIndex(
            (item) =>
              item.id === Number(rest.worksheet.currentWorksheetVersionId)
          ) ?? 0
        ]?.worksheetVersionItems?.length ?? 0
      ).fill(true)
    );

    // Calculate the height of the top navigation dynamically
    if (topNavRef.current) {
      setTopNavHeight(topNavRef.current.offsetHeight);
    }

    const handleResize = () => {
      if (topNavRef.current) {
        setTopNavHeight(topNavRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageLoad = (index: number) => {
    console.log("Image loaded", index);
    setLoadingStates((prev) => {
      const updatedStates = [...prev];
      updatedStates[index] = false; // Mark image as loaded
      return updatedStates;
    });
  };

  const handleImageError = (index: number) => {
    console.error("Image failed to load", index);
    setLoadingStates((prev) => {
      const updatedStates = [...prev];
      updatedStates[index] = true; // Even if error, stop skeleton
      return updatedStates;
    });
  };

  // Set up IntersectionObserver to track the active page
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let mostVisiblePageIndex = activePageIndex.current;
        let maxIntersectionRatio = 0;

        entries.forEach((entry) => {
          const index = pageRefs.current.indexOf(
            entry.target as HTMLDivElement
          );
          if (index !== -1 && entry.intersectionRatio > maxIntersectionRatio) {
            mostVisiblePageIndex = index;
            maxIntersectionRatio = entry.intersectionRatio;
          }
        });

        if (mostVisiblePageIndex !== activePageIndex.current) {
          activePageIndex.current = mostVisiblePageIndex;
          render();
          refetchPageDetail(); // refetch the worksheet page details
        }
      },
      {
        root: null, // Observe relative to the viewport
        threshold: [0.25, 0.5, 0.75, 1], // Update at various visibility percentages
      }
    );

    // Observe each page div
    pageRefs.current.forEach((page) => {
      if (page) {
        observer.observe(page);
      }
    });

    // Clean up observer on unmount
    return () => {
      observer.disconnect();
    };
  }, [data, activePageIndex]);

  const {
    textInputs,
    setTextInputs,
    updateText,
    removeText,
    updateDragPosition,
  } = useTextInputs({
    student: [],
    teacher: [],
  });
  const {
    drawingSettings,
    setMode,
    changeColor,
    changeFontSize: changeDrawingFontSize,
  } = useDrawingSettings({
    stroke: 3,
    color: "#000",
    mode: MODES.PEN,
    fontSize: DEFAULT_FONT_SIZE,
  });

  const getContext = useCallback(
    (config: DrawingSettings | null, ctx: CanvasRenderingContext2D | null) => {
      if (!context.current) {
        context.current = canvas.current!.getContext("2d");
      }
      if (!ctx && context.current) {
        ctx = context.current;
      }
      if (config && ctx) {
        ctx.strokeStyle = config.color;
        ctx.lineWidth = config.stroke;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
      }
      return ctx;
    },
    []
  );

  const drawModes = (
    mode: MODES,
    ctx: CanvasRenderingContext2D,
    point: Pair<number, number> | null,
    path: Pairs<number, number>
  ) => {
    switch (mode) {
      case MODES.PEN:
        point ? previewPen(point, ctx) : drawPen(path, ctx);
        break;
      case MODES.RECT:
        if (point) {
          path.length === 0 ? (path[0] = point) : (path[1] = point);
          previewRect(path, ctx);
        } else {
          drawRect(path, ctx);
        }
        break;
      case MODES.CIRCLE:
        if (point) {
          path.length === 0 ? (path[0] = point) : (path[1] = point);
          previewCircle(path, ctx);
        } else {
          drawCircle(path, ctx);
        }
        break;
      default:
        return;
    }
  };

  const isOnline = useNavigatorOnline();

  const saveCanvas = async (
    drawingHistories: DrawingHistory[],
    textInputs: DraggableTextInput[]
  ) => {
    const worksheetPageVersionId = Number(
      data?.worksheetVersion?.[0]?.worksheetVersionItems?.[
        activePageIndex.current
      ]?.worksheetPageVersion?.id
    );
    if (rest.teacherView) {
      await upsertMarkingSessionMutation.mutateAsync({
        worksheetSetAssignmentId: Number(
          dataAssigment?.getAssignmentDetail?.id
        ),
        markedQuestions: Object.entries(pointsState).map(
          ([worksheetPageQuestionId, points]) => ({
            worksheetPageQuestionId: parseInt(worksheetPageQuestionId),
            points,
          })
        ),
      });
    }
    mutation.mutate({
      worksheetPageVersionId,
      userId: Number(auth.user?.id),
      role,
      worksheetSetAssignmentId: Number(dataAssigment?.getAssignmentDetail?.id),
      drawing: drawingHistories,
      textboxes: (textInputs ?? []).map((input) => ({
        text: input.text,
        position: { x: input.x, y: input.y },
        fontSize: input.fontSize,
      })),
    });
  };

  const saveCanvasHandler = () => {
    saveCanvas(history.current[role], textInputs[role]);
  };

  const {
    clearCanvas,
    drawCanvas,
    undoCanvas,
    redoCanvas,
    importCanvas,
    history,
    redoHistory,
  } = useCanvasActions({
    context,
    height,
    getContext,
    drawModes,
    role,
    saveCanvas: saveCanvasHandler,
  });

  const {
    lastPath,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onTouchStart,
    onTouchEnd,
    onTouchMove,
  } = useCanvasEvents({
    canvas,
    history,
    redoHistory,
    drawCanvas,
    width,
    height,
    drawingSettings,
    context,
    textInputs,
    setTextInputs,
    setMode,
    getContext,
    drawModes,
    saveCanvas: saveCanvasHandler,
    role,
  });

  const mutation = useUpsertWorksheetPageAnnotation();
  const upsertMarkingSessionMutation = useUpsertMarkingSession();

  const saveButtonClickHandler = () => {
    if (rest.worksheet.isLastWorksheet) {
      saveCanvas(history.current[role], textInputs[role]);
      const redirectURI = rest.teacherView
        ? `/teacher/session/${rest.sessionId}/student/${rest.studentId}/set/${rest.setVersionId}/scores`
        : "/student";
      navigate({ to: redirectURI });
    } else {
      saveCanvas(history.current[role], textInputs[role]);
      const nextPageURI = rest.teacherView
        ? `/teacher/session/${rest.sessionId}/student/${rest.studentId}/set/${rest.setVersionId}/worksheet/${rest.worksheet.nextWorksheetVersionId}`
        : `/student/session/${rest.sessionId}/set/${rest.setVersionId}/worksheet/${rest.worksheet.nextWorksheetVersionId}`;
      navigate({
        to: nextPageURI,
      });
    }
  };

  useEffect(() => {
    if (isOnline && history?.current?.[role]?.length) {
      saveCanvasHandler();
    }
  }, [isOnline, history]);

  const navigate = useNavigate();

  const previewRect = (
    path: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    if (path.length < 2) return;
    drawCanvas(ctx);
    const newCtx = getContext(drawingSettings.current, ctx);
    if (!newCtx) {
      return;
    }
    drawRect(path, newCtx);
  };

  const drawRect = (
    path: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    ctx.beginPath();
    ctx.rect(
      path[0][0],
      path[0][1],
      path[1][0] - path[0][0],
      path[1][1] - path[0][1]
    );
    ctx.stroke();
  };

  const previewCircle = (
    path: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    if (path.length < 2) return;
    drawCanvas(ctx);
    getContext(drawingSettings.current!, ctx); // reset context
    drawCircle(path, ctx);
  };

  const getDistance = ([[p1X, p1Y], [p2X, p2Y]]: Pairs<number, number>) => {
    return Math.sqrt(Math.pow(p1X - p2X, 2) + Math.pow(p1Y - p2Y, 2));
  };

  const drawCircle = (
    path: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    ctx.beginPath();
    ctx.arc(path[0][0], path[0][1], getDistance(path), 0, 2 * Math.PI);
    ctx.stroke();
  };

  const previewPen = (
    point: Pair<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    if (lastPath.current.length === 0) {
      ctx.beginPath();
      ctx.moveTo(point[0], point[1]);
    }
    ctx.lineTo(point[0], point[1]);
    ctx.stroke();
    lastPath.current.push(point);
  };

  const drawPen = (
    points: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);
    for (const p of points) {
      ctx.lineTo(p[0], p[1]);
    }
    ctx.stroke();
  };

  useEffect(() => {
    const ctx = getContext(null, null);
    // Initial Data
    history.current[role] = [];
    history.current[siblingRole] = [];
    // Add data from API to the canvas
    const hasRoleDrawingAnnotation =
      dataPageDetail?.getWorksheetPageVersionDetail?.annotations?.[role]?.[0]
        ?.drawing;
    const hasSiblingRoleDrawingAnnotation =
      dataPageDetail?.getWorksheetPageVersionDetail?.annotations?.[
        siblingRole
      ]?.[0]?.drawing;

    if (hasRoleDrawingAnnotation) {
      history.current[role] = dataPageDetail?.getWorksheetPageVersionDetail
        ?.annotations?.[role]?.[0]?.drawing as unknown as DrawingHistory[];
    }

    if (hasSiblingRoleDrawingAnnotation) {
      history.current[siblingRole] = dataPageDetail
        ?.getWorksheetPageVersionDetail?.annotations?.[siblingRole]?.[0]
        ?.drawing as unknown as DrawingHistory[];
    }

    const hasRoleTextboxes =
      dataPageDetail?.getWorksheetPageVersionDetail?.annotations?.[role]?.[0]
        ?.textboxes;
    const hasSiblingRoleTextboxes =
      dataPageDetail?.getWorksheetPageVersionDetail?.annotations?.[
        siblingRole
      ]?.[0]?.textboxes;

    if (hasRoleTextboxes) {
      setTextInputs({
        ...textInputs,
        [role]: (
          dataPageDetail.getWorksheetPageVersionDetail?.annotations?.[role]?.[0]
            ?.textboxes as Textbox[]
        )?.map((input: Textbox) => {
          return {
            id: uuidv4(),
            x: input.position.x,
            y: input.position.y,
            text: input.text,
            fontSize: input.fontSize || drawingSettings.current.fontSize,
          };
        }),
      });
    }

    if (hasSiblingRoleTextboxes) {
      setTextInputs({
        ...textInputs,
        [siblingRole]: (
          dataPageDetail.getWorksheetPageVersionDetail?.annotations?.[
            siblingRole
          ]?.[0]?.textboxes as Textbox[]
        )?.map((input: Textbox) => {
          return {
            id: uuidv4(),
            x: input.position.x,
            y: input.position.y,
            text: input.text,
            fontSize: input.fontSize || drawingSettings.current.fontSize,
          };
        }),
      });
    }

    if (ctx) {
      clearCanvas(ctx);
      drawCanvas(ctx);
    }
    render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPageDetail, drawingSettings]);

  useEffect(() => {
    document.addEventListener("pointerup", onPointerUp);
    document.addEventListener("touchend", onTouchEnd);
    document.addEventListener("pointermove", onPointerMove);
    document.addEventListener("touchmove", onTouchMove);
    const ctx = getContext(null, null);
    ctx?.setTransform(1, 0, 0, 1, -CANVAS_WIDTH / 2, -(PAN_LIMIT - height) / 2);
    const newCtx = getContext(null, null);
    if (newCtx) {
      drawCanvas(newCtx);
    }
    return () => {
      document.removeEventListener("pointerup", onPointerUp);
      document.removeEventListener("touchend", onTouchEnd);
      document.removeEventListener("pointermove", onPointerMove);
      document.removeEventListener("touchmove", onTouchMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, textInputs, setTextInputs]);

  return (
    <div className="relative w-full h-[calc(100vh-64px)] flex">
      {/* Left Container */}
      <div className={`relative w-full h-full flex flex-col`}>
        {/* Top Navigation within Left Container (Stacked Grid) */}
        <div
          ref={topNavRef}
          className="sticky top-0 bg-white z-10 border-b border-gray-200"
        >
          <WorksheetSetThumbnails
            worksheetSetVersionId={rest.setVersionId}
            currentWorksheetVersionId={rest.worksheet.currentWorksheetVersionId}
            teacherView={rest.teacherView}
            studentId={rest.studentId}
            classroomSessionId={rest.sessionId}
          />
        </div>

        {/* Floating Actions (Dynamically positioned based on Top Navigation height) */}
        <div
          style={{ top: `${topNavHeight}px` }}
          className="sticky z-20 flex justify-between px-4"
        >
          {(mutation.isPending || upsertMarkingSessionMutation.isPending) && (
            <div className="flex gap-2 items-center w-full justify-center p-4">
              <Spinner />
              Saving your work...
            </div>
          )}
          <div className="">
            <CanvasToolbar
              drawingSettings={drawingSettings}
              setMode={setMode}
              changeColor={changeColor}
              undoCanvas={undoCanvas}
              isLoading={isPending}
              redoCanvas={redoCanvas}
              historyLength={history?.current?.[role]?.length}
              redoHistoryLength={redoHistory?.current?.[role]?.length}
            />
          </div>
          <div className="absolute right-0 z-20 p-4">
            <div className="grid gap-4 justify-items-center">
              {/* Right Menu */}
              <div
                className={cn("bg-gray-200 rounded-sm p-6 grid gap-3")}
                onPointerDown={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
                onPointerUp={(e) => e.stopPropagation()}
                onTouchEnd={(e) => e.stopPropagation()}
              >
                <button
                  className={cn("button")}
                  type="button"
                  onClick={saveCanvasHandler}
                  disabled={
                    history?.current?.[role]?.length === 0 &&
                    textInputs?.[role]?.length === 0
                  }
                >
                  {isPending ? (
                    <Skeleton className="w-5 aspect-square" />
                  ) : (
                    <img src={exportIcon} alt="export" title="export" />
                  )}
                </button>
                <div>
                  <label htmlFor="import-canvas"></label>
                  <input
                    name="import-canvas"
                    ref={importInput}
                    className="hidden"
                    type="file"
                    accept="application/json"
                    placeholder="Import Canvas"
                    onChange={importCanvas}
                  />
                </div>
                <button
                  className="button"
                  type="button"
                  title="Next Page Button"
                  onClick={() => {
                    navigate({
                      to: "/student/session/$sessionId/set/$setVersionId/worksheet/$worksheetVersionId",
                      params: {
                        sessionId: rest.sessionId,
                        setVersionId: rest.setVersionId,
                        worksheetVersionId: (
                          Number(rest.worksheet.currentWorksheetVersionId) + 1
                        ).toString(),
                      },
                    });
                  }}
                >
                  <ArrowBigRightDash />
                </button>
              </div>
              {/* Pass Indicator */}
              {!rest.teacherView &&
                dataAssigment?.getAssignmentDetail?.isMarkingComplete &&
                !!dataMarkingSession?.markingSession?.length &&
                dataMarkingSession?.markingSession?.[0].markedQuestions && (
                  <div className="grid gap-4">
                    {dataPageDetail?.getWorksheetPageVersionDetail?.questions?.map(
                      (question, index) => {
                        const markedQuestion =
                          dataMarkingSession?.markingSession?.[0]?.markedQuestions?.find(
                            (marked) =>
                              marked?.worksheetPageQuestion?.id === question?.id
                          );

                        // Assuming total points and scored points are relevant to determining pass/fail
                        const totalPoints = question?.totalPoints ?? 0;
                        const scoredPoints = markedQuestion?.points ?? 0;
                        const scorePercentage =
                          totalPoints > 0 ? scoredPoints / totalPoints : 0;
                        const passed = scorePercentage >= PASSING_THRESHOLD;

                        return (
                          <div
                            key={question?.id}
                            className="flex items-center gap-4 cursor-pointer"
                          >
                            <Popover>
                              <PopoverTrigger>
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger asChild>
                                      <div
                                        className={cn(
                                          "aspect-square w-12 rounded-full",
                                          {
                                            "bg-green-300": passed,
                                            "bg-red-400": !passed,
                                          }
                                        )}
                                      />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <span>{`Question ${index + 1}: ${passed ? "Passed" : "Failed"}`}</span>
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </PopoverTrigger>
                              <PopoverContent className="w-80">
                                <span>{`Question ${index + 1}: ${passed ? "Passed" : "Failed"}`}</span>
                              </PopoverContent>
                            </Popover>
                          </div>
                        );
                      }
                    )}
                  </div>
                  // <div>
                  //   {/* Use markingSessionOverride if it exists */}
                  //   {dataMarkingSessionOverride?.markingSessionOverride?.some(
                  //     (item) =>
                  //       item?.worksheetVersionId ===
                  //       Number(rest.worksheet.currentWorksheetVersionId)
                  //   ) ? (
                  //     <div
                  //       className={cn("aspect-square w-12 rounded-full", {
                  //         "bg-green-300":
                  //           dataMarkingSessionOverride?.markingSessionOverride?.some(
                  //             (item) =>
                  //               item?.worksheetVersionId ===
                  //                 Number(
                  //                   rest.worksheet.currentWorksheetVersionId
                  //                 ) && item?.overrideStatus === "pass"
                  //           ),
                  //         "bg-red-400":
                  //           dataMarkingSessionOverride?.markingSessionOverride?.some(
                  //             (item) =>
                  //               item?.worksheetVersionId ===
                  //                 Number(
                  //                   rest.worksheet.currentWorksheetVersionId
                  //                 ) && item?.overrideStatus === "fail"
                  //           ),
                  //       })}
                  //     />
                  //   ) : (
                  //     <div
                  //       className={cn("aspect-square w-12 rounded-full", {
                  //         "bg-green-300":
                  //           markingResult?.[
                  //             Number(rest.worksheet.currentWorksheetVersionId)
                  //           ],
                  //         "bg-red-400":
                  //           !markingResult?.[
                  //             Number(rest.worksheet.currentWorksheetVersionId)
                  //           ],
                  //       })}
                  //     />
                  //   )}
                  // </div>
                )}
            </div>
          </div>
        </div>

        {/* Scrollable Content Below */}
        <div className="canvas-container relative flex-grow overflow-y-auto">
          {/* Integrate Canvas */}
          <div>
            <div className="relative">
              <div className="relative">
                <canvas
                  ref={canvas}
                  width={CANVAS_WIDTH}
                  height={height}
                  onPointerDown={onPointerDown}
                  onTouchStart={onTouchStart}
                  className={cn(
                    drawingSettings.current!.mode === MODES.PAN
                      ? "moving"
                      : "drawing",
                    "z-10 touch-none relative margin-auto"
                  )}
                />
              </div>

              <div className="z-30 absolute top-0">
                {[
                  ...(textInputs[role] ?? []),
                  ...(textInputs[siblingRole] ?? []),
                ]?.map((input) => (
                  <EditableText
                    mode={drawingSettings.current.mode}
                    key={input.id}
                    id={input.id}
                    position={{ x: input.x, y: input.y }}
                    text={input.text}
                    setText={(id, text) => updateText(id, text, role)}
                    remove={(id) => removeText(id, role)}
                    onDrag={(id, x, y) => updateDragPosition(id, x, y, role)}
                    fontSize={
                      input.fontSize ?? drawingSettings.current?.fontSize
                    }
                    changeFontSize={(id, delta) => {
                      setTextInputs({
                        ...textInputs,
                        [role]: textInputs[role].map((input) => {
                          if (input.id === id) {
                            const newFontSize = Math.max(
                              8,
                              (input.fontSize || 12) + delta
                            );
                            changeDrawingFontSize(newFontSize); // Make new text using latest font size used
                            return { ...input, fontSize: newFontSize };
                          }
                          return input;
                        }),
                      });
                      render();
                    }}
                    saveCanvas={saveCanvasHandler}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* Images of the Content */}
          <div className="absolute top-0 z-0" style={{ width: CANVAS_WIDTH }}>
            {data?.worksheetVersion?.[
              data?.worksheetVersion?.findIndex(
                (item) =>
                  item.id === Number(rest.worksheet.currentWorksheetVersionId)
              ) ?? 0
            ]?.worksheetVersionItems?.map((item, index) => (
              <div
                key={item.id}
                className="mb-4 relative"
                ref={(el) => {
                  if (el) {
                    pageRefs.current[index] = el;
                  }
                }}
              >
                {/* Skeleton Loader if image is still loading */}
                {loadingStates[index] && (
                  <div className="bg-gray-200 animate-pulse aspect-portrait w-full h-full absolute top-0 text-center">
                    <div className="pt-8">
                      {`Page ${index + 1} is loading...`}
                    </div>
                  </div>
                )}

                {/* Image once it's loaded */}
                <img
                  src={item?.worksheetPageVersion?.filename}
                  alt={`Worksheet Page ${item?.worksheetPageVersion?.id}`}
                  className={clsx("w-full h-auto ", {
                    "aspect-portrait": loadingStates[index],
                  })}
                  onLoad={() => handleImageLoad(index)}
                  onError={() => handleImageError(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Right Container (Sticky Answer Key) */}
      {showAnswerKey && rest.teacherView && (
        <div className="h-full w-5/6 bg-blue-100  z-10 p-4">
          {/* Answer Key Display */}
          <div className="sticky top-0">
            <div className="grid gap-2">
              <h3 className="text-lg font-bold">Answer Key</h3>
              {dataPageDetail?.getWorksheetPageVersionDetail?.answerKey
                ?.filename ? (
                <div className="h-screen">
                  <img
                    className="aspect-portrait bg-slate-300 scale-75 max-h-svh"
                    src={String(
                      dataPageDetail?.getWorksheetPageVersionDetail?.answerKey
                        ?.filename
                    )}
                    alt={String(
                      dataPageDetail?.getWorksheetPageVersionDetail?.answerKey
                        ?.filename
                    )}
                  />
                </div>
              ) : (
                <div>
                  <p>No answer key available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {rest.teacherView && (
        <div className="h-full w-1/6 bg-red-100  z-10 p-4">
          {/* Question Display */}
          <div className="sticky top-0">
            <div className="grid gap-2">
              <h3 className="text-lg font-bold">Questions</h3>{" "}
              {dataPageDetail?.getWorksheetPageVersionDetail?.questions?.map(
                (question, index) => (
                  <div key={question?.id} className="grid gap-1 mb-2">
                    <span className="mr-4">{question?.content}</span>
                    <div className="flex items-center">
                      <input
                        type="number"
                        className="border p-1 w-16"
                        min={0}
                        max={Number(question?.totalPoints)}
                        value={
                          pointsState?.[question?.id ?? 0] ??
                          question?.markedQuestion?.points ??
                          null ??
                          ""
                        }
                        onChange={(e) =>
                          handlePointsChange(
                            e,
                            question?.id ?? 0,
                            question?.totalPoints ?? 0
                          )
                        }
                        onFocus={(e) => {
                          if (e.currentTarget.value === "") {
                            e.currentTarget.value = "0"; // Set the value to 0 if it's empty when focused
                          }
                        }}
                        aria-label={`Points for question ${index + 1}`}
                      />
                      <span className="ml-2">
                        / {question?.totalPoints} points
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}

      {/* Bottom Actions */}
      <div className="fixed bottom-0 w-full bg-white p-4 border-t border-gray-200 z-20">
        <div className="flex justify-between">
          {rest.teacherView ? (
            <div className="flex gap-2 divide-x-2">
              {/* Student Navigation */}
              <WorksheetStudentNavigation
                sessionId={Number(rest.sessionId)}
                currentActiveStudentId={Number(rest.studentId)}
              />
              {/* Toggle Answer Key */}
              <label className="flex items-center space-x-3 px-2">
                <input
                  type="checkbox"
                  checked={showAnswerKey}
                  onChange={() => setShowAnswerKey(!showAnswerKey)}
                  className="form-checkbox"
                />
                <span>
                  Show Answer Key for Page {activePageIndex.current + 1}
                </span>
              </label>
            </div>
          ) : (
            <div />
          )}
          <div className="flex gap-2">
            {rest.teacherView && (
              <Link
                to="/teacher/session/$sessionId/student/$userId/set/$setVersionId/scores"
                className={buttonVariants({ variant: "outline" })}
                params={{
                  sessionId: rest.sessionId,
                  userId: String(rest.studentId),
                  setVersionId: rest.setVersionId,
                }}
              >
                Go to Score Card
              </Link>
            )}
            <button
              className={buttonVariants({ variant: "default" })}
              onClick={saveButtonClickHandler}
            >
              Save and Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Canvas;
