import { UpdateWorksheetSetAssignmentDocument, UpdateWorksheetSetAssignmentMutation, UpdateWorksheetSetAssignmentMutationVariables } from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import request from "graphql-request";
export function useUpdateWorksheetSetAssignment(): UseMutationResult<
  UpdateWorksheetSetAssignmentMutation,
  Error,
  UpdateWorksheetSetAssignmentMutationVariables
> {
  return useMutation({
    mutationKey: ["updateWorksheetSetAssignment"],
    mutationFn: async ({ where, set }: UpdateWorksheetSetAssignmentMutationVariables) => {
      try {
        const result = await request<UpdateWorksheetSetAssignmentMutation>(
          config.graphql.uri,
          UpdateWorksheetSetAssignmentDocument,
          { where, set }
        );
        return result;
      } catch (error) {
        throw new Error(`Failed to update worksheet set assignment: ${error}`);
      }
    },
  });
}
