import {
  GetWorksheetVersionDocument,
  InputMaybe,
  WorksheetVersionFilters,
} from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetWorksheetVersion({
  where,
  enabled,
}: {
  where: InputMaybe<WorksheetVersionFilters> | undefined;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: ["getWorksheetVersion", where],
    queryFn: async () =>
      request(config.graphql.uri, GetWorksheetVersionDocument, {
        where: where,
      }),
    enabled: enabled,
  });
}
