import { UpsertMarkingSessionOverrideDocument } from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useMutation } from "@tanstack/react-query";
import request from "graphql-request";

export function useUpsertMarkingSessionOverride() {
  return useMutation({
    mutationKey: ["upsertMarkingSessionOverride"],
    mutationFn: ({
      markingSessionId,
      worksheetSetVersionId,
      worksheetVersionId,
      overrideStatus,
      remarks,
    }: {
      markingSessionId: number;
      worksheetSetVersionId: number;
      worksheetVersionId: number;
      overrideStatus: string;
      remarks: string | null;
    }) =>
      request(config.graphql.uri, UpsertMarkingSessionOverrideDocument, {
        markingSessionId,
        worksheetSetVersionId,
        worksheetVersionId,
        overrideStatus,
        remarks,
      }),
  });
}
