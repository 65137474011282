import { createFileRoute, redirect } from "@tanstack/react-router";
import { useAuth } from "@/lib/useAuth";
import { Spinner } from "@/components/ui/spinner";

import WorksheetSets from "@/components/worksheet-sets";
import ErrorHandler from "@/components/error-handler";
import { useGetWorksheetSetAssigment } from "@/lib/apis/v2/queries/getWorksheetSetAssigment";

export const Route = createFileRoute("/_auth/student/")({
  component: () => <StudentHomePage />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function StudentHomePage() {
  const auth = useAuth();
  const { isPending, error, data } = useGetWorksheetSetAssigment({
    where: {
      garageUserId: {
        eq: Number(auth?.user?.id),
      },
    },
  });

  if (isPending) {
    return <Spinner />;
  }

  if (error || !auth.user?.id) {
    return <ErrorHandler error={error || new Error("Unable to load")} />;
  }

  return (
    <div className="p-4">
      <h1 className="text-lg font-bold p-4">My Worksheets</h1>
      <section className="grid gap-2">
        <WorksheetSets data={data} />
      </section>
    </div>
  );
}
