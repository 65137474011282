import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "./ui/button";
import { useNavigate } from "@tanstack/react-router";
import { GetWorksheetSetAssigmentQuery } from "@/graphql/generated/graphql";
import { cn } from "@/lib/utils";

// Map assignments to include a lock status
const lockAssignments = (
  assignments: GetWorksheetSetAssigmentQuery["worksheetSetAssignment"]
) => {
  const sortedAssignments = [...(assignments ?? [])].sort(
    (a, b) =>
      new Date(a?.createdAt).getTime() - new Date(b?.createdAt).getTime()
  );
  let previousCompleted = true; // Initially assume no previous assignment
  return sortedAssignments.map((assignment) => {
    const isLocked = !previousCompleted;
    // Update previousCompleted for the next iteration
    previousCompleted = assignment?.isMarkingComplete || false;
    return {
      ...assignment,
      isLocked,
    };
  });
};

export default function WorksheetSets({
  data,
  studentId,
}: {
  data: GetWorksheetSetAssigmentQuery;
  studentId?: string;
}) {
  const navigate = useNavigate();

  const assignmentsWithLock = lockAssignments(data.worksheetSetAssignment);

  return (
    <div className="grid grid-cols-3 gap-2">
      {(assignmentsWithLock ?? [])
        .map((assignment) => (
          <Card
            key={assignment?.id}
            className={cn("my-2 justify-center align-middle", {
              "opacity-0": !studentId && assignment?.isLocked,
            })}
          >
            <CardHeader>
              <CardTitle>
                Worksheet: {assignment?.worksheetSetVersion?.worksheetSet?.name}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                Assigned in SessionID: {assignment?.garageClassroomSessionId}
              </CardDescription>
            </CardContent>
            <CardFooter>
              {studentId ? (
                <div className="flex justify-between gap-2 flex-wrap">
                  <Button
                    onClick={() => {
                      navigate({
                        to: "/teacher/session/$sessionId/student/$userId/set/$setVersionId/worksheet/$worksheetVersionId",
                        params: {
                          sessionId: String(
                            assignment?.garageClassroomSessionId
                          ),
                          userId: String(studentId),
                          setVersionId: String(
                            assignment?.worksheetSetVersion?.id
                          ),
                          worksheetVersionId: String(
                            assignment?.worksheetSetVersion
                              ?.worksheetSetVersionItems?.[0]?.id
                          ),
                        },
                      });
                    }}
                    className="w-full"
                  >
                    View worksheet set
                  </Button>
                  <Button
                    onClick={() =>
                      navigate({
                        to: "/teacher/session/$sessionId/student/$userId/set/$setVersionId/scores",
                        params: {
                          sessionId: String(
                            assignment?.garageClassroomSessionId
                          ),
                          userId: String(studentId),
                          setVersionId: String(
                            assignment?.worksheetSetVersion?.id
                          ),
                        },
                      })
                    }
                    className="w-full"
                  >
                    View Score Card
                  </Button>
                </div>
              ) : assignment.isLocked ? (
                <div className="w-full">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className="w-full">
                        <Button disabled className="w-full">
                          Locked
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Complete previous worksheet set to unlock.</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              ) : (
                <Button
                  onClick={() => {
                    navigate({
                      to: "/student/session/$sessionId/set/$setVersionId/worksheet/$worksheetVersionId",
                      params: {
                        sessionId: String(assignment?.garageClassroomSessionId),
                        setVersionId: String(
                          assignment?.worksheetSetVersion?.id
                        ),
                        worksheetVersionId: String(
                          assignment?.worksheetSetVersion
                            ?.worksheetSetVersionItems?.[0]?.worksheetVersionId
                        ),
                      },
                    });
                  }}
                  className="w-full"
                >
                  Start Learning!
                </Button>
              )}
            </CardFooter>
          </Card>
        ))}
    </div>
  );
}
