import {
  MarkedQuestionInput,
  UpsertMarkingSessionDocument,
} from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useMutation } from "@tanstack/react-query";
import request from "graphql-request";

export function useUpsertMarkingSession() {
  return useMutation({
    mutationKey: ["upsertMarkingSession"],
    mutationFn: ({
      worksheetSetAssignmentId,
      markedQuestions,
    }: {
      worksheetSetAssignmentId: number;
      markedQuestions: MarkedQuestionInput[];
    }) =>
      request(config.graphql.uri, UpsertMarkingSessionDocument, {
        worksheetSetAssignmentId,
        markedQuestions,
      }),
  });
}
