const getConfig = (key: string, defaultValue: string): string => {
  return (window as never)[key] || process.env[key] || defaultValue;
};

const getGraphQLUri = (defaultPath: string): string => {
  const host = window.location.origin; // Get the current host (protocol + host + port)
  return `${host}${defaultPath}`;
};

export default {
  environment: getConfig("VITE_ENVIRONMENT", "staging"),
  sentryDsn: getConfig("VITE_SENTRY_DSN", ""),
  sentrySampleRate: getConfig("VITE_SENTRY_SAMPLE_RATE", "0.5"),
  graphql: {
    uri: getConfig("VITE_GRAPHQL_URI", getGraphQLUri("/graphql")),
  },
};
