import { createFileRoute, Link, redirect } from "@tanstack/react-router";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Spinner } from "@/components/ui/spinner";
import ErrorHandler from "@/components/error-handler";
import { useGetStaffSchedule } from "@/lib/apis/v2/queries/getStaffSchedule";

export const Route = createFileRoute("/_auth/operations/schedules/")({
  component: () => <SessionsList />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
    if (
      context.auth.user?.role !== "teacher" &&
      context.auth.user?.role !== "ops"
    ) {
      throw redirect({ to: "/student" });
    }
  },
});

function SessionsList() {
  const {
    data: dataCR,
    isPending: pendingCR,
    error: errorCR,
  } = useGetStaffSchedule({});

  if (errorCR) {
    return <ErrorHandler error={errorCR as Error} />;
  }

  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div className="mx-auto grid w-full max-w-6xl gap-2">
          <h1 className="text-3xl font-semibold">My Schedule</h1>
        </div>
        <div className="mx-auto grid w-full max-w-6xl">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
            {pendingCR && <Spinner />}
            {!pendingCR &&
              dataCR?.getStaffSchedule &&
              (dataCR?.getStaffSchedule ?? []).map((session) => (
                <Card key={session?.id}>
                  <CardHeader>
                    <CardTitle>{session?.title}</CardTitle>
                    <CardDescription>
                      <div className="flex justify-between">
                        <div className="flex flex-col">
                          <div className="text-xs">Session Time:</div>
                          <div className="grid">
                            <div>{session?.day}</div>
                            <div>
                              {`${session?.start_time} - ${session?.end_time}`}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-xs">Class Type:</div>
                          <div>
                            {session?.is_offline ? "Offline" : "Online"}
                          </div>
                        </div>
                      </div>
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="flex justify-between">
                      <div className="flex flex-col">
                        <div className="text-xs text-gray-500">Teacher</div>
                        <div>{`${session?.teacher?.name}`}</div>
                      </div>
                    </div>
                  </CardContent>
                  <CardFooter className="border-t px-6 py-4">
                    <div className="w-full flex justify-end">
                      <Button variant="outline">
                        <Link
                          to="/teacher/session/$sessionId"
                          params={{ sessionId: String(session?.id) }}
                        >
                          View Students
                        </Link>
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              ))}
          </div>
        </div>
      </main>
    </div>
  );
}
