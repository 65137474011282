import {
  InsertWorksheetSetAssignmentDocument,
  WorksheetSetAssignmentInsertInput,
} from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useMutation } from "@tanstack/react-query";
import request from "graphql-request";

export function useInsertWorksheetSetAssignment() {
  return useMutation({
    mutationKey: ["insertWorksheetSetAssignment"],
    mutationFn: ({ values }: { values: WorksheetSetAssignmentInsertInput }) =>
      request(config.graphql.uri, InsertWorksheetSetAssignmentDocument, {
        values: values,
      }),
  });
}
