import { createFileRoute, redirect, useRouter } from "@tanstack/react-router";

import bgImage from "@/assets/bg.jpg";
import { z } from "zod";
import { useEffect, useState } from "react";
import { Spinner } from "@/components/ui/spinner";
import { ErrorPage } from "@/components/error-page";
import { useQueryClient } from "@tanstack/react-query";
import { captureException } from "@sentry/react";
import { useLoginWithToken } from "@/lib/apis/v2/auth/loginWithToken";
import { useAuth } from "@/lib/useAuth";

const fallback = "/home" as const;

export const Route = createFileRoute("/token-login")({
  component: Token,
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
    token: z.string().optional().catch(""),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: search.redirect || fallback });
    }
  },
});

export function Token() {
  const loginWithToken = useLoginWithToken();
  const navigate = Route.useNavigate();
  const router = useRouter();
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const auth = useAuth();

  const search = Route.useSearch();

  useEffect(() => {
    if (!search.token) {
      setError("Token not found");
      return;
    }
    loginWithToken.mutate(search.token, {
      onSuccess: async () => {
        await auth.getMeAndSetUser();
        queryClient.invalidateQueries();
      },
      onError: (err) => {
        setError("Something went wrong. Please contact Edukita Team.");
        captureException(err);
      },
    });
  }, [search.token, search.redirect, router, navigate, queryClient]);

  if (error) {
    return <ErrorPage message={error} />;
  }

  return (
    <div className="w-full lg:grid lg:grid-cols-2 lg:min-h-screen">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Logging in...</h1>
            <Spinner />
          </div>
        </div>
      </div>
      <div className="bg-muted block">
        <img
          src={bgImage}
          alt="Image"
          width="1920"
          height="1080"
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
    </div>
  );
}
