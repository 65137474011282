import { GetStudentPaginatedDocument } from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetStudentPaginated({
  name,
  page,
  perPage,
}: {
  name?: string;
  page?: number;
  perPage?: number;
}) {
  return useQuery({
    queryKey: ["getStudentPaginated", name, page, perPage],
    queryFn: async () =>
      request(config.graphql.uri, GetStudentPaginatedDocument, {
        name,
        page,
        perPage,
      }),
  });
}
