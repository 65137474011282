import { api } from "@/lib/apis/v2/client.tsx";
import { useMutation } from "@tanstack/react-query";

async function loginWithToken(token: string) {
  const response = await api.auth.token.$post({
    json: { token },
  });
  const data = await response.json();
  return data;
}

export function useLoginWithToken() {
  return useMutation({
    mutationKey: ["loginWithToken"],
    mutationFn: loginWithToken,
  });
}
