import { Spinner } from "../ui/spinner";
import ErrorHandler from "../error-handler";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import { Link } from "@tanstack/react-router";
import { cn, getWorksheetVersionPassFailMap } from "@/lib/utils";
import { useGetWorksheetVersion } from "@/lib/apis/v2/queries/getWorksheetVersion";
import { useGetWorksheetSetVersion } from "@/lib/apis/v2/queries/getWorksheetSetVersion";
import { CircleCheck, CircleAlert } from "lucide-react";
import { isEmpty } from "lodash-es";
import { useGetAssignmentDetail } from "@/lib/apis/v2/queries/getAssignmentDetail";
import { useGetMarkingSessions } from "@/lib/apis/v2/queries/getMarkingSessions";
import { useAuth } from "@/lib/useAuth";
import { useGetMarkingSessionOverride } from "@/lib/apis/v2/queries/getMarkingSessionOverride";

interface WorksheetSetThumbnailsProps {
  classroomSessionId: string;
  worksheetSetVersionId: string;
  currentWorksheetVersionId: string;
  teacherView?: boolean;
  studentId?: string;
}

export default function WorksheetSetThumbnails({
  worksheetSetVersionId,
  currentWorksheetVersionId,
  teacherView,
  studentId,
  classroomSessionId,
}: WorksheetSetThumbnailsProps) {
  const auth = useAuth();
  const { data: dt } = useGetWorksheetSetVersion({
    where: {
      id: {
        eq: Number(worksheetSetVersionId),
      },
    },
  });

  const { data, error, isPending } = useGetWorksheetVersion({
    where: {
      id: {
        inArray: [
          ...(dt?.worksheetSetVersion?.[0]?.worksheetSetVersionItems?.map(
            (item) => Number(item?.worksheetVersion?.id)
          ) ?? []),
        ],
      },
    },
    enabled: !isEmpty(
      dt?.worksheetSetVersion?.[0].worksheetSetVersionItems?.map((item) =>
        Number(item?.worksheetVersion?.id)
      )
    ),
  });

  const { data: dataAssigment } = useGetAssignmentDetail({
    garageClassroomSessionId: Number(classroomSessionId),
    garageUserId: teacherView ? Number(studentId) : Number(auth.user?.id),
    worksheetSetVersionId: Number(worksheetSetVersionId),
    enabled: !!worksheetSetVersionId && !!classroomSessionId,
  });

  const { data: dataMarkingSession } = useGetMarkingSessions({
    where: {
      worksheetSetAssignmentId: {
        eq: Number(dataAssigment?.getAssignmentDetail?.id),
      },
    },
    enabled: !!dataAssigment?.getAssignmentDetail?.id,
  });

  const { data: dataMarkingSessionOverride } = useGetMarkingSessionOverride({
    where: {
      worksheetSetVersionId: {
        eq: Number(worksheetSetVersionId),
      },
    },
    enabled: !!worksheetSetVersionId,
  });

  const markingResult = getWorksheetVersionPassFailMap({
    markingSessionData: dataMarkingSession,
    worksheetVersionData: data,
  });

  if (isPending) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error as Error} />;
  }

  return (
    <div className="w-2/3 m-auto p-4">
      <div className="w-auto m-auto items-center">
        <Carousel>
          <CarouselContent>
            {(dt?.worksheetSetVersion?.[0].worksheetSetVersionItems ?? []).map(
              (wsi) => (
                <CarouselItem
                  className="basis-1/8"
                  key={wsi?.worksheetVersion?.id}
                >
                  <div
                    className={cn("w-16", {
                      "border-2 border-primary opacity-100":
                        Number(wsi?.worksheetVersion?.id) ===
                        Number(currentWorksheetVersionId),
                    })}
                  >
                    {teacherView && studentId ? (
                      <Link
                        to="/teacher/session/$sessionId/student/$userId/set/$setVersionId/worksheet/$worksheetVersionId"
                        params={{
                          sessionId: classroomSessionId,
                          userId: studentId,
                          setVersionId: worksheetSetVersionId,
                          worksheetVersionId: String(wsi?.worksheetVersion?.id),
                        }}
                        className={cn(
                          " rounded-lg flex items-center justify-center relative",
                          {
                            "bg-green-100":
                              Number(wsi?.id) <
                              Number(currentWorksheetVersionId),
                          }
                        )}
                      >
                        <img
                          src={
                            data?.worksheetVersion
                              ?.find(
                                (item) => item.id === wsi.worksheetVersion?.id
                              )
                              ?.worksheetVersionItems?.find(
                                (item) => item.worksheetPageVersion
                              )?.worksheetPageVersion?.filename
                          }
                          alt={wsi?.worksheetVersion?.worksheet?.name}
                          className={cn("object-cover w-full h-full", {
                            "opacity-50 hover:opacity-100 transition-opacity duration-200":
                              Number(wsi?.worksheetVersion?.id) <
                              Number(currentWorksheetVersionId),
                          })}
                        />
                      </Link>
                    ) : (
                      <Link
                        to="/student/session/$sessionId/set/$setVersionId/worksheet/$worksheetVersionId"
                        params={{
                          sessionId: classroomSessionId,
                          setVersionId: worksheetSetVersionId,
                          worksheetVersionId: String(wsi?.worksheetVersion?.id),
                        }}
                        className={cn(
                          " rounded-lg flex items-center justify-center relative",
                          {
                            "bg-green-100":
                              Number(wsi?.worksheetVersion?.id) <
                              Number(currentWorksheetVersionId),
                          }
                        )}
                      >
                        <img
                          src={
                            data?.worksheetVersion
                              ?.find(
                                (item) => item.id === wsi.worksheetVersion?.id
                              )
                              ?.worksheetVersionItems?.find(
                                (item) => item.worksheetPageVersion
                              )?.worksheetPageVersion?.filename
                          }
                          alt={wsi?.worksheetVersion?.worksheet?.name}
                          className={cn("object-cover w-full h-full", {
                            "opacity-50 hover:opacity-100 transition-opacity duration-200":
                              Number(wsi?.worksheetVersion?.id) <
                              Number(currentWorksheetVersionId),
                          })}
                        />

                        {dataAssigment?.getAssignmentDetail
                          ?.isMarkingComplete &&
                          dataMarkingSessionOverride && (
                            <>
                              {/* Use markingSessionOverride if it exists */}
                              {dataMarkingSessionOverride.markingSessionOverride.some(
                                (item) =>
                                  item?.worksheetVersionId ===
                                  Number(wsi?.worksheetVersion?.id)
                              ) ? (
                                <>
                                  <CircleCheck
                                    className={`absolute top-8 left-3 m-2 ${
                                      dataMarkingSessionOverride.markingSessionOverride.some(
                                        (item) =>
                                          item?.worksheetVersionId ===
                                            Number(wsi?.worksheetVersion?.id) &&
                                          item?.overrideStatus === "pass"
                                      )
                                        ? "text-green-500"
                                        : "hidden"
                                    }`}
                                  />
                                  <CircleAlert
                                    className={`absolute top-8 left-3 m-2 ${
                                      dataMarkingSessionOverride.markingSessionOverride.some(
                                        (item) =>
                                          item?.worksheetVersionId ===
                                            Number(wsi?.worksheetVersion?.id) &&
                                          item?.overrideStatus === "fail"
                                      )
                                        ? "text-red-500"
                                        : "hidden"
                                    }`}
                                  />
                                </>
                              ) : (
                                <>
                                  <CircleCheck
                                    className={`absolute top-8 left-3 m-2 ${
                                      markingResult[
                                        Number(wsi?.worksheetVersion?.id)
                                      ] ||
                                      Number(wsi?.worksheetVersion?.id) <
                                        Number(currentWorksheetVersionId)
                                        ? "text-green-500"
                                        : "hidden"
                                    }`}
                                  />
                                  <CircleAlert
                                    className={`absolute top-8 left-3 m-2 ${
                                      markingResult[
                                        Number(wsi?.worksheetVersion?.id)
                                      ] === false
                                        ? "text-red-500"
                                        : "hidden"
                                    }`}
                                  />
                                </>
                              )}
                            </>
                          )}
                      </Link>
                    )}
                  </div>
                </CarouselItem>
              )
            )}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
    </div>
  );
}
