import { GetStaffScheduleDocument } from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetStaffSchedule({ email }: { email?: string }) {
  return useQuery({
    queryKey: ["getStaffSchedule", email],
    queryFn: async () =>
      request(config.graphql.uri, GetStaffScheduleDocument, { email }),
  });
}
