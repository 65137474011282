import {
  GetWorksheetSetVersionDocument,
  InputMaybe,
  WorksheetSetVersionFilters,
} from "@/graphql/generated/graphql";
import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";

export function useGetWorksheetSetVersion({
  where,
}: {
  where: InputMaybe<WorksheetSetVersionFilters> | undefined;
}) {
  return useQuery({
    queryKey: ["getWorksheetSetVersion", where],
    queryFn: async () =>
      request(config.graphql.uri, GetWorksheetSetVersionDocument, {
        where: where,
      }),
  });
}
